import { questionData } from "./questionsData";

const countQuestionsPerCategory = (questionData) => {
  const categoryCounts = {};

  for (const category in questionData) {
    // Replace "&" with an empty string in the category name
    const cleanCategory = category.replace(/&/g, '');
    const FinalCategory = cleanCategory.replace(/-/g, '');

    const categoryQuestions = questionData[category];
    const totalQuestions = categoryQuestions.length;

    let totalFollowUpQuestions = 0;

    // Collect both main questions and their follow-ups
    const allQuestions = [];
    categoryQuestions.forEach((question) => {
      allQuestions.push(question.id);
      if (question.followUpQuestion) {
        allQuestions.push(...question.followUpQuestion.map((followUp) => followUp.id));
        totalFollowUpQuestions += question.followUpQuestion.length;
      }
    });

    const combinedTotal = totalQuestions + totalFollowUpQuestions;

    categoryCounts[FinalCategory] = {
      questions: allQuestions,
      combinedTotal,
    };
  }

  return categoryCounts;
};

export const calculateLensMetricsFunction = (answeredQuestions, categoryCounts) => {
  const lensCompletion = {};
  const lensStrength = {};

  for (const category in categoryCounts) {
    const categoryDetails = categoryCounts[category];
    const categoryQuestions = categoryDetails.questions;
    const totalQuestions = categoryDetails.combinedTotal;

    let completionCount = 0;
    let strengthCount = 0;

    categoryQuestions.forEach((questionId) => {
      const userResponse = answeredQuestions[questionId];

      // Skip null values
      if (userResponse !== null && userResponse !== undefined) {
        // If the value is -1 or between 0 and 10, calculate lensCompletion
        if (userResponse === -1 || (userResponse >= 0 && userResponse <= 10)) {
          completionCount += 1;
        }

        // If the value is between 0 and 10, calculate lensStrength
        if (userResponse >= 0 && userResponse <= 10) {
          strengthCount += 1;
        }

        // Check if there are follow-up questions
        const followUpIds = answeredQuestions[`${questionId}.followUpQuestion`] || [];
        followUpIds.forEach((followUpId) => {
          const followUpResponse = answeredQuestions[followUpId];
          // Include follow-up question responses in the calculation
          if (followUpResponse !== null && followUpResponse !== undefined && followUpResponse >= 0 && followUpResponse <= 10) {
            strengthCount += 1;
            if (followUpResponse !== -1) {
              completionCount += 1;
            }
          }
        });
      }
    });

    // Calculate the percentage of questions with values between 0 and 10 for lensCompletion
    const completionPercentage = (completionCount / totalQuestions) * 100;
    lensCompletion[category] = isNaN(completionPercentage) ? 0 : Number(completionPercentage.toFixed(2));

    // Calculate the percentage of questions with values between 0 and 10 for lensStrength
    const strengthPercentage = (strengthCount / totalQuestions) * 100;
    lensStrength[category] = isNaN(strengthPercentage) ? 0 : Number(strengthPercentage.toFixed(2));
  }

  return { lensCompletion, lensStrength };
};


export const lensCounts = countQuestionsPerCategory(questionData);