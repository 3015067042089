export function calculateScore(questionWeights, questionsData, minOutput, maxOutput) {
    const score = Object.keys(questionsData).reduce((sum, id) => {
      const value = sum + questionWeights[id] * questionsData[id];
      return (value - minOutput) / (maxOutput - minOutput);
    }, 0);
  
    return score;
  }
  
export function calculateGenericScore(answeredQuestions, scoringInfo) {
  const { questionWeights, minOutput, maxOutput, driverQuestions } = scoringInfo;

  // Extract values for specific question IDs and filter out -1 and null values
  const questionIds = Object.keys(questionWeights);
  const questionsData = {};

  questionIds.forEach((id) => {
    const value = answeredQuestions[id];
    if (value !== undefined && value !== null && value !== -1) {
      questionsData[id] = value;
    }
  });

  // Check if none of the driver questions have been answered
  const noneOfDriverQuestionsAnswered = driverQuestions.every((id) => questionsData[id] === undefined);

  if (noneOfDriverQuestionsAnswered) {
    return null;
  }

  const result = calculateScore(questionWeights, questionsData, minOutput, maxOutput);

  return result;
}

export function selectProcess(marketNewness, companyNewness, riskTolerance, opportunityType) {
  const marketWeight = 0.5;
  const companyWeight = 0.4;
  const riskWeight = 0.1;
  
  let usedMarketNewness = marketNewness === null ? 1 : marketNewness;
  let usedCompanyNewness = companyNewness === null ? 1 : companyNewness;
  let usedRiskTolerance = riskTolerance === null ? 1 : riskTolerance;

  let score = (marketWeight * usedMarketNewness) + (companyWeight * usedCompanyNewness) + (riskWeight * usedRiskTolerance);
  
  let selectedProcess = "";

  if (score >= 0 && score <= 0.3) {
      selectedProcess = "Express-Process.webp";
  } else if (score > 0.3 && score <= 0.6) {
      selectedProcess = "Lite-Process.webp";
  } else if (score > 0.6 && score <= 1) {
      selectedProcess = "Complete-Process.webp";
  } else {
      selectedProcess = "Complete-Process.webp"; 
  }

  if (opportunityType === 'hardware' || opportunityType === 'combination') {
    selectedProcess = selectedProcess = "Complete-Process.webp"; 
  }

  return selectedProcess;
}

  