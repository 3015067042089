import React from "react";
import '../styles/Header.css'

export default function Header() {
    return (
        <div className="headerContainer">
            <div className="prodivioLogoContainer">
                <img src="/p-logo-long-white.webp" alt="Prodivio Logo" className="providio-logo"/>
            </div>
            <div className="rjsContainer">
                <p>Developed by</p>
                <a href="https://byrjs.blog" target="_blank" rel="noopener noreferrer">
                    <img src="/logo-round.webp" alt="RJS Logo" className="rjs-logo" />
                </a>
            </div>
    </div>
    )
}