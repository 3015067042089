import React from "react";
import { useSelector } from "react-redux";
import { renderTable} from "../BE/displayFunctions";
import { Chart } from "react-google-charts";
import { getLandscapeInsights } from "../BE/variablesRelationalModel";

export default function LandscapeAnalysis() {

    const resultScores = useSelector((state) => state.results.scores);
    const opportunityName = useSelector((state) => state.questionnaire.opportunityData.opportunityName);

    const marketNewnessScore = resultScores.marketNewness;
    const companyNewnessScore = resultScores.companyNewness;
    const marketCrowdednessScore = resultScores.marketCrowdedness;
    const marketEvolutionScore = resultScores.marketEvolution;
    const marketReadinessScore = resultScores.marketReadiness;
    const marketSubstitutionScore = resultScores.marketSubstitution;
    const marketDisruptivenessScore = resultScores.marketDisruptiveness;
    const riskToleranceScore = resultScores.riskTolerance;
    const frequencyOfInnovationScore = resultScores.frequencyOfInnovation;
    const typeOfInnovationScore = resultScores.typeOfInnovation;
    const economyStateScore = resultScores.economyState;

    const {EOInsights, marketNewnessInsights, companyNewnessInsights, marketCrowdednessInsights, marketEvolutionInsights, marketReadinessInsights, marketSubstitutionInsights, marketDisruptivenessInsights, riskToleranceInsights, frequencyOfInnovationInsights, typeOfInnovationInsights, economyStateInsights, economyStateRiskInsights, economyStateMarketInsights} = getLandscapeInsights(resultScores);

    const degreeEntrepreneurshipScore = (riskToleranceScore + typeOfInnovationScore) / 2;

    const EOData = [
        ["ID", "Degree of Entrepreneurship", "Frequency of Innovation"],
        ["My Company", degreeEntrepreneurshipScore || 0, frequencyOfInnovationScore || 0],
      ];

      const EOChartOptions = {
        hAxis: {
          title: "Degree of Entrepreneurship",
          minValue: 0,
          maxValue: 1,
          ticks: [{v: 0, f: 'Low'}, {v: 0.5, f: 'Medium'}, {v: 1, f: 'High'}],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        vAxis: {
          title: "Frequency of Innovation",
          minValue: 0,
          maxValue: 1,
          ticks: [{v: 0, f: 'Low'}, {v: 0.5, f: 'Medium'}, {v: 1, f: 'High'}],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        sizeAxis: {
          minValue: 0,
          maxValue: 1,
          minSize: 5,
          maxSize: 10,
        },
        tooltip: { trigger: 'none' },
      };

    const MCNewnessData = [
        ["ID", "Market Newness", "Company Newness"],
        [opportunityName, marketNewnessScore || 0, companyNewnessScore || 0],
    ]

    const MCNewnessDataOptions = {
        hAxis: {
          title: "Market Newness",
          minValue: 0,
          maxValue: 1,
          ticks: [{v: 0, f: 'Low'}, {v: 0.5, f: 'Medium'}, {v: 1, f: 'High'}],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        vAxis: {
          title: "Company Newness",
          minValue: 0,
          maxValue: 1,
          ticks: [{v: 0, f: 'Low'}, {v: 0.5, f: 'Medium'}, {v: 1, f: 'High'}],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        sizeAxis: {
          minValue: 0,
          maxValue: 1,
          minSize: 5,
          maxSize: 10,
        },
        tooltip: { trigger: 'none' },
      };

    const riskData = [
        ["Variable", "Score"],
        ["Risk Tolerance", riskToleranceScore || 0],
    ]

    const marketDeepDiveData = [
        ["Variable", "Score"],
        ["Competition", marketCrowdednessScore || 0],
        ["Need", marketEvolutionScore],
        ["Pace", marketReadinessScore],
        ["Substitutability", marketSubstitutionScore],
        ["Disruption", marketDisruptivenessScore],
    ]

    const economyData = [
        ["Variable", "Score"],
        ["Economy State", economyStateScore || 0],
    ]
    
    const LineChartOptions = {
        chartArea: { width: "50%" },
        hAxis: {
          title: "Score",
          minValue: 0,
          maxValue: 1,
          ticks: [
            { v: 0, f: 'Low' },
            { v: 0.5, f: 'Medium' },
            { v: 1, f: 'High' },
            
          ],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        vAxis: {
          title: "Variables",
        },
        legend: 'none',
        tooltip: { trigger: 'none' },
      };


    return (
        <div className="landscapeContainer">
            <h2>Landscape</h2>
            <p>Before you dive headfirst into developing your innovative idea, it's essential to take a moment and understand your starting point. Like any journey, having a clear picture of where you are helps you chart the best course for success.</p>
            <div className="companyInsightsContainer">
            <h3>Company</h3>
                <div className="EOContainer">
                    <h4>Entrepreneurial Orientation</h4>
                    <p>Entrepreneurial Orientation is an organization's attitude and actions that reflect its proactiveness, risk-taking, and innovativeness in pursuing new opportunities. It essentially describes how a company approaches and engages with entrepreneurial endeavors.</p>
                    <Chart chartType="BubbleChart" width="100%" height="400px" data={EOData} options={EOChartOptions} />
                    {renderTable(EOInsights)}
                    <h5>Risk Tolerance</h5>
                    <p>A company's risk tolerance refers to its willingness to take on risk in pursuit of potential rewards. This can significantly impact the types of opportunities a company is open to pursuing and the level of investment they are willing to make. </p>
                    <Chart chartType="BarChart" width="100%" data={riskData} options={LineChartOptions} />
                    {renderTable(riskToleranceInsights)}
                </div>
            </div>

            <div className="marketCompanyNewnessContainer">
            <h3>Market</h3>
                <h4>Market & Company Newness</h4>
                <p>To develop your product or service effectively, it's important to understand how new it is to both the market and your company. Market newness refers to whether your product or service introduces something completely new or builds on existing concepts. Company newness refers to whether your company has experience in this area or if it's a completely new direction. Understanding these factors will help you make informed decisions and increase your chances of success.</p>
                <Chart chartType="BubbleChart" width="100%" height="400px" data={MCNewnessData} options={MCNewnessDataOptions} />
                {renderTable(companyNewnessInsights)}
                {renderTable(marketNewnessInsights)}

            </div>
            <div className="marketDeepDiveContainer">
                <h4>Market Deep-Dive</h4>
                <p>This section delves into a detailed analysis of key market variables that can significantly impact the potential success of your opportunity.</p>
                <Chart chartType="BarChart" width="100%" data={marketDeepDiveData} options={LineChartOptions} />
                <div className="marketDeepDiveInsightsContainer">
                    <div className="sideBySideInsight">
                        <h5>Competition:</h5>
                        {renderTable(marketCrowdednessInsights)}
                    </div>
                    <div className="sideBySideInsight">
                        <h5>Need:</h5>
                        {renderTable(marketReadinessInsights)}
                    </div>
                    <div className="sideBySideInsight">
                        <h5>Pace:</h5>
                        {renderTable(marketEvolutionInsights)}
                    </div>
                    <div className="sideBySideInsight">
                        <h5>Substitutability:</h5>
                        {renderTable(marketSubstitutionInsights)}
                    </div>
                    <div className="sideBySideInsight">
                        <h5>Disruption:</h5>
                        {renderTable(marketDisruptivenessInsights)}
                    </div>
                </div>

            </div>
            <div className="economyContainer">
                <h3>Economy</h3>
                <p>Beyond the specific market factors discussed previously, it's crucial to consider the broader economic climate when evaluating your opportunity's potential. This section focuses on perceptions of the economic conditions, which can significantly impact consumer behavior and overall market sentiment</p>
                <Chart chartType="BarChart" width="100%" data={economyData} options={LineChartOptions} />
                {renderTable(economyStateInsights)}
                <h5>The economy and the risk tolerance of the company/managers</h5>
                {renderTable(economyStateRiskInsights)}
                <h5>The economy and the market newness for your opportunity</h5>
                {renderTable(economyStateMarketInsights)}
            </div>
        </div>
    )
}