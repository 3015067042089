/* import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    marketNewness: null,
    companyNewness: null,
    marketCrowdedness: null,
    marketEvolution: null,
    marketReadiness: null,
    marketSubstitutability: null,
    marketDisruptiveness: null,
  };

const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    setMarketNewness: (state, action) => {
      state.marketNewness = action.payload;
    },
    setCompanyNewness: (state, action) => {
      state.companyNewness = action.payload;
    },
    setMarketCrowdedness: (state, action) => {
      state.marketCrowdedness = action.payload;
    },
    setMarketEvolution: (state, action) => {
      state.marketEvolution = action.payload;
    },
    setMarketReadiness: (state, action) => {
      state.marketReadiness = action.payload;
    },
    setMarketSubstitutability: (state, action) => {
      state.marketSubstitutability = action.payload;
    },
    setMarketDisruptiveness: (state, action) => {
      state.marketDisruptiveness = action.payload;
    },
  },
});

export const { setMarketNewness, setCompanyNewness } = resultsSlice.actions;
export default resultsSlice.reducer; */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Define an object to store scores for different variables
  scores: {},
};

const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    setScore: (state, action) => {
      const { variable, score } = action.payload;
      // Update the scores object with the new score
      state.scores[variable] = score;
    },
  },
});

export const { setScore } = resultsSlice.actions;
export default resultsSlice.reducer;