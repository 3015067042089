import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { assessmentCategories } from "../BE/assessmentCategories";

export default function AssessmentNavigation() {
  const navigate = useNavigate();
  const { category } = useParams();

  // State to track the current category index
  const [currentCategory, setCurrentCategory] = useState(0);

  // useEffect to update the current category when the URL changes
  useEffect(() => {
    const currentCategoryIndex = assessmentCategories.findIndex(
      (cat) => cat.replace(/\s+/g, '-') === category
    );
    setCurrentCategory(currentCategoryIndex >= 0 ? currentCategoryIndex : 0);
  }, [category]);

  // Function to handle forward navigation
  const handleForward = () => {
    const nextCategoryIndex = currentCategory + 1;

    if (nextCategoryIndex < assessmentCategories.length) {
      const nextCategory = assessmentCategories[nextCategoryIndex];
      navigate(`/assessment/${nextCategory.replace(/\s+/g, '-')}`);
      window.scrollTo(0, 0);
    } else {
      // If it's the last section, navigate to the results summary page
      navigate('/results/Summary');
      window.scrollTo(0, 0);
    }
  };

  // Function to handle backward navigation
  const handleBackward = () => {
    const prevCategoryIndex = currentCategory - 1;
    if (prevCategoryIndex >= 0) {
      const prevCategory = assessmentCategories[prevCategoryIndex];
      navigate(`/assessment/${prevCategory.replace(/\s+/g, '-')}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="navigationButtonContainer">
      <button className="backwardButton inactiveButton" onClick={handleBackward}>
        Previous section
      </button>
      <button className="fowardNavButton activeButton" onClick={handleForward} >
        {currentCategory === assessmentCategories.length - 1 ? "Go to Results" : "Next section"}
      </button>
    </div>
  );
}