import React from "react";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import { getHowInsights } from "../BE/variablesRelationalModel";
import { renderTable } from "../BE/displayFunctions";



export default function HowAnalysis() {
    
    const resultScores = useSelector((state) => state.results.scores);
    const { companyKnowledgeInsights, companyImpactInsights, monetizationInsights, distributionInsights, gtmResourcesInsights, marketingInsights, salesStrategyInsights, logisticsInsights, customerCareInsights, legalRiskInsights, legalIPInsights, ethicalRiskInsights, investmentSizeInsights, executionResourcesInsights} = getHowInsights(resultScores);

    const companyKnowledgeScore = resultScores.companyKnowledge;
    const companyImpactScore = resultScores.companyImpact;
    const monetizationScore = resultScores.monetization;
    const distributionScore = resultScores.distribution;
    const gtmResourcesScore = resultScores.gtmResources;
    const marketingScore = resultScores.marketing;
    const salesStrategyScore = resultScores.salesStrategy;
    const logisticsScore = resultScores.logistics;
    const customerCareScore = resultScores.customerCare;
    const legalRiskScore = resultScores.legalRisk;
    const legalIPScore = resultScores.legalIP;
    const ethicalRiskScore = resultScores.ethicalRisk;
    const investmentSizeScore = resultScores.investmentSize;
    const executionResourcesScore = resultScores.executionResources;

    const companyImpactData = [
        ["Variable", "Score"],
        ["Company Knowledge", companyKnowledgeScore || 0],
        ["Company Impact", companyImpactScore],
    ]

    const GTMData = [
        ["Variable", "Score"],
        ["Monetization", monetizationScore || 0],
        ["Distribution", distributionScore],
        ["Marketing", marketingScore],
        ["Sales Strategy", salesStrategyScore],
        ["Logistics", logisticsScore],
        ["Customer Care", customerCareScore],
        ["GTM Resources", gtmResourcesScore],
    ]

    const legalData = [
        ["Variable", "Score"],
        ["Legal Risk", legalRiskScore || 0],
        ["Ethical Risk", ethicalRiskScore],
        ["Legal IP", legalIPScore],
    ]

    const executionResourcesData = [
        ["Variable", "Score"],
        ["Investment Size", investmentSizeScore || 0],
        ["Execution Resources", executionResourcesScore],
    ]

    const LineChartOptions = {
        chartArea: { width: "50%" },
        hAxis: {
          title: "Score",
          minValue: 0,
          maxValue: 1,
          ticks: [
            { v: 0, f: 'Low' },
            { v: 0.5, f: 'Medium' },
            { v: 1, f: 'High' },
            
          ],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        vAxis: {
          title: "Variables",
        },
        legend: 'none',
        tooltip: { trigger: 'none' },
      };

    return (
        <div className="howContainer">
            <h2>How</h2>
            <p>We've explored your desired goals, defined your starting point, and understood the inner workings of the opportunity. Now, it's time to see how this innovative idea takes off.</p>
            <div className="companyImpactContainer">
                <h3>Company Impact</h3> 
                <Chart chartType="BarChart" width="100%" data={companyImpactData} options={LineChartOptions} />
                <div className="sideBySideInsight">
                        <h5>Company Knowledge:</h5>
                        {renderTable(companyKnowledgeInsights)}
                </div>
                <div className="sideBySideInsight">
                        <h5>Company Impact:</h5>
                        {renderTable(companyImpactInsights)}
                </div>
            </div>

            <div className="GTMContainer">
                <h3>Go-To-Market</h3>
                <Chart chartType="BarChart" width="100%" data={GTMData} options={LineChartOptions} />
                <div className="sideBySideInsight">
                        <h5>Monetization:</h5>
                        {renderTable(monetizationInsights)}
                </div>
                <div className="sideBySideInsight">
                        <h5>Distribution:</h5>
                        {renderTable(distributionInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Marketing:</h5>
                    {renderTable(marketingInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Sales Strategy:</h5>
                    {renderTable(salesStrategyInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Logistics:</h5>
                    {renderTable(logisticsInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Customer Care:</h5>
                    {renderTable(customerCareInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Go-To-Market Resources:</h5>
                    {renderTable(gtmResourcesInsights)}
                </div>

            </div>

            <div className="legalContainer">
                <h3>Legal</h3>
                <Chart chartType="BarChart" width="100%" data={legalData} options={LineChartOptions} />
                <div className="sideBySideInsight">
                    <h5>Legal Risk:</h5>
                    {renderTable(legalRiskInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Ethical Risk:</h5>
                    {renderTable(ethicalRiskInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Legal IP:</h5>
                    {renderTable(legalIPInsights)}
                </div>
            </div>

            <div className="executionResourcesContainer">
                <h3>Execution Resources</h3>
                <Chart chartType="BarChart" width="100%" data={executionResourcesData} options={LineChartOptions} />
                <div className="sideBySideInsight">
                    <h5>Investment Size:</h5>
                    {renderTable(investmentSizeInsights)}
                </div>
                <div className="sideBySideInsight">
                    <h5>Execution Resources:</h5>
                    {renderTable(executionResourcesInsights)}
                </div>
            </div>
        </div>
    )
}