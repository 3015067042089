import React, {useEffect} from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import '../styles/App.css';

//import components
import WelcomeScreen from '../pages/WelcomeScreen';
import AssessmentCenter from '../pages/AssessmentCenter';
import AssessmentCategoryContent from '../components/AssessmentCategoryContent';
import NotFoundPage from '../pages/NotFoundPage';
import ResultsCenter from '../pages/ResultsCenter';
import ResultsContent from '../components/ResultsContent';


const router = createBrowserRouter([
  {
    path: '/',
    element: (
    <>
      <WelcomeScreen />
    </>
    ),
    errorElement: <NotFoundPage />,
  },
  {
    path: '/assessment',
    element: (
      <>
      <AssessmentCenter />
    </>

    ),
    
    children: [
      {
        path: ':category',
        element: (
          <>
          <AssessmentCategoryContent />
        </>
        )

      }
    ]
  },
  {
    path: '/results',
    element: (
      <>
        <ResultsCenter />
      </>
    ),


    children: [
      {
        path: ':category',
        element: (
          <>
          <ResultsContent />
        </>
)
      }
    ]
  }

]);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {


  return (
    <>
      <RouterProvider router={router}>
        <ScrollToTop />
      </RouterProvider>
    </>
  );
}

export default App;
