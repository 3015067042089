import React from "react";
import { useParams } from "react-router-dom";
import ResultsAnalysis from "./ResultsAnalysis";
import ResultsSummary from "./ResultsSummary";
import Recommendations from "./Recommendations";

export default function ResultsContent() {

    const { category } = useParams();
    const formattedCategory = category.replace(/-/g, ' ')

    const componentList = {
        'Summary': <ResultsSummary />,
        'Analysis': <ResultsAnalysis />,
        'Recommendations': <Recommendations />
    }

    const componentToDisplay = componentList[formattedCategory] || <h1>Coming soon!</h1>;


  return (
    <div>
        {componentToDisplay}
    </div>
  );
};
