import { createSlice } from "@reduxjs/toolkit";
import { lensCounts, calculateLensMetricsFunction } from "../../BE/lensModel";

const initialState = {
  questionValues: {},
  opportunityData: {
    opportunityName: "My amazing opportunity",
    email: "",
    opportunityType: "",
  },
  lensCompletion: {}, 
  lensStrength: {}, 
};

const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    setOpportunityData: (state, action) => {
      const { opportunityName, email, opportunityType } = action.payload;
      state.opportunityData.opportunityName = opportunityName;
      state.opportunityData.email = email;
      state.opportunityData.opportunityType = opportunityType;
    },
    setQuestionValue: (state, action) => {
      const { questionId, value } = action.payload;
      state.questionValues[questionId] = value;
    },
    clearQuestionnaire: (state) => {
      // Reset all questionnaire-related state to initial values
      state.questionValues = {};
      state.opportunityData = {
        opportunityName: "My amazing opportunity",
        email: "",
        opportunityType: "",
      };
      state.lensCompletion = {};
      state.lensStrength = {};
    },
    calculateLensMetrics: (state) => {
      // Call your utility function to calculate both lens completion and strength
      const lensMetricsResult = calculateLensMetricsFunction(state.questionValues, lensCounts);

      // Update the state with the lensMetrics result
      state.lensCompletion = lensMetricsResult.lensCompletion;
      state.lensStrength = lensMetricsResult.lensStrength;
    },
  },
});

export const { setOpportunityData, setQuestionValue, clearQuestionnaire, calculateLensMetrics } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;