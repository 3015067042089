import { configureStore } from '@reduxjs/toolkit';
import checkTCReducer from '../features/checkTC/checkTCSlice';
import questionnaireReducer from '../features/questionnaire/questionnaireSlice';
import resultsReducer from '../features/Results/resultsSlice';

// Check localStorage for stored data
const storedQuestionnaire = JSON.parse(localStorage.getItem('myReduxQuestionnaire'));



// Configure Redux store with stored data or initial state
export const store = configureStore({
  reducer: {
    checkTC: checkTCReducer,
    questionnaire: questionnaireReducer,
    results: resultsReducer,
  },
  preloadedState: {
    questionnaire: storedQuestionnaire || questionnaireReducer.initialState,
    // Add other preloaded states as needed
  },
});