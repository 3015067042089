import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { selectProcess } from "../BE/scoringModel";
import { getLensComparisonRisks } from "../BE/lensRelationalModel";
import { renderRisksTableFromArray } from "../BE/displayFunctions";


export default function RecommendationProcess() {
    const marketNewness = useSelector((state) => state.results.scores.marketNewness);
    const companyNewness = useSelector((state) => state.results.scores.companyNewness);
    const riskTolerance = useSelector((state) => state.results.scores.riskTolerance);
    const opportunityType = useSelector((state) => state.questionnaire.opportunityData.opportunityType);

    const selectedProcess = selectProcess(marketNewness, companyNewness, riskTolerance);

    const lensStrength = useSelector((state) => state.questionnaire.lensStrength);

    const { lensComparisonFoundationalRisks, lensComparisonProgressiveRisks } = getLensComparisonRisks(lensStrength);

    // Handle case when selectedProcess is null or undefined
    if (!selectedProcess) {
        console.error("No process selected or invalid process");
        return null; // Or return a placeholder image or message
    }

    let missingInformation;
    if (marketNewness === null || companyNewness === null || riskTolerance === null || opportunityType === "" ) {
        const missingVariables = [];

        if (marketNewness === null) {
            missingVariables.push("Market Newness");
        }
        if (companyNewness === null) {
            missingVariables.push("Company Newness");
        }
        if (riskTolerance === null) {
            missingVariables.push("Risk Tolerance");
        }
        
        if (opportunityType === "") {
            missingVariables.push("Opportunity Type")
        }

        missingInformation = (
            <div className="missingInformation">
                <p><strong>Caution: </strong>We might need some information to calculate one or several metrics correctly. This process might change when you complete the questionnaire.</p>
                <p>We need some information to calculate one or several metrics correctly. This process might change when you complete the questionnaire.</p>
                <ul>
                    {missingVariables.map((variable, index) => (
                        <li key={index}>{variable}</li>
                    ))}
                </ul>
            </div>
        );
    }

    let keyInsights
    if (marketNewness !== null || companyNewness !== null || riskTolerance !== null || opportunityType !== "") {
        let marketRating;
        if (marketNewness >= 0 && marketNewness <= 0.3) {
            marketRating = 'Low';
        } else if (marketNewness > 0.3 && marketNewness <= 0.6) {
            marketRating = 'Medium';
        } else if (marketNewness > 0.6 && marketNewness <= 1) {
            marketRating = 'High';
        }
    
        // Check companyNewness rating
        let companyRating;
        if (companyNewness >= 0 && companyNewness <= 0.3) {
            companyRating = 'Low';
        } else if (companyNewness > 0.3 && companyNewness <= 0.6) {
            companyRating = 'Medium';
        } else if (companyNewness > 0.6 && companyNewness <= 1) {
            companyRating = 'High';
        }
    
        // Check riskTolerance rating
        let riskRating;
        if (riskTolerance >= 0 && riskTolerance <= 0.3) {
            riskRating = 'Low';
        } else if (riskTolerance > 0.3 && riskTolerance <= 0.6) {
            riskRating = 'Medium';
        } else if (riskTolerance > 0.6 && riskTolerance <= 1) {
            riskRating = 'High';
        }
        let opportunityRating;
        if (opportunityType === "software") {
            opportunityRating = "Software";
        } else if (opportunityType === "hardware") {
            opportunityRating = "Physical Product"
        } else if (opportunityType === "combination") {
            opportunityRating = "Combination of Hardware and Software"
        }

        keyInsights = (
            <div className="keyInsights">
                <h3>Key Insights:</h3>
                <p>We recommend this process because of the following variables:</p>
                <ul>
                    {marketNewness !== null && <li>Market Newness has a {marketRating} rating</li>}
                    {companyNewness !== null && <li>Company Newness has a {companyRating} rating</li>}
                    {riskTolerance !== null && <li>Risk Tolerance has a {riskRating} rating</li>}
                    {opportunityType !== "" && <li>Your opportunity is a {opportunityRating} project which has inherent risks on its own</li>}
                </ul>
            </div>
        );
    }

    return (
        <div className="recommendationProcess"> {/* Corrected class name */}
            {missingInformation}
            <img
                src={`/recommendations/${selectedProcess}`}
                className="processImage"
                alt={selectedProcess} // Provide alt text for accessibility
            />
            {keyInsights}
             <div className="lensStrengthRisks">
                    <div className="riskExplanation">
                        <h3>Risks:</h3>
                        <p>We can identify two types of risks from the questionnaire. These risks stem from areas within the questionnaire where information may be lacking, or responses indicate uncertainty with answers such as "I don't know."</p>
                        <ul>
                            <li>
                                <strong>Foundational Risks:</strong> As the name implies, foundational risks pertain to critical aspects of the project's foundation. They address fundamental information crucial for decision-making in the early stages of a project. Identifying and addressing foundational risks early on is essential for establishing a solid groundwork and ensuring the project's success as it progresses.
                            </li>
                            <li>
                                <strong>Progressive Risks:</strong> In contrast, progressive risks may not manifest immediately but can potentially impact the project later in its development lifecycle. These risks evolve and may arise from factors such as changing market conditions, evolving stakeholder requirements, or unforeseen technical challenges. It's important to monitor and mitigate progressive risks to safeguard the project's long-term success and resilience against potential disruptions.
                            </li>
                        </ul>
                </div>
                {renderRisksTableFromArray(lensComparisonFoundationalRisks, 'Foundational')}
                {renderRisksTableFromArray(lensComparisonProgressiveRisks, 'Progressive')}
            </div>
        </div>
    );
}