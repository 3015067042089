import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import '../styles/AssessmentCenter.css';
import { useSelector } from "react-redux";
import { assessmentCategories } from '../BE/assessmentCategories';
import AssessmentSideDrawer from "../components/AssessmentSideDrawer";

export default function AssessmentCenter() {
  const navigate = useNavigate();
  const location = useLocation();
  const checkTC = useSelector((state) => state.checkTC.value);
  const pathname = location.pathname;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!checkTC) {
      navigate('/');
    }

    // Update the title based on the current route
    document.title = `Prodivio Forge - Assessment: ${getTitleFromPathname(pathname)}`;
  }, [checkTC, navigate, location]);

  useEffect(() => {
    // Check if the screen is mobile based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to extract title from pathname
  const getTitleFromPathname = (pathname) => {
    return pathname.replace(/^\/assessment\//, '').replace(/-/g, ' ');
  };

  return (
    <div className="appContainer">
      <Header />
      <div className="screenContainer">
        <div className="navigationContainer">
          <img src="/P-Forge.webp" alt="Prodivio Forge Logo" className="pForge-logo" />
          <NavLink to='/assessment/Summary' className={`navLink ${location.pathname.startsWith('/assessment') ? 'activeAssessment' : ''}`} >Assessment</NavLink>
          <NavLink to='/results/Summary' className="navLink">Results</NavLink>
          <div className="emptyNavSpace"></div>
        </div>
        <div className="mainContainer">
          {isMobile ? (
            <>
            <AssessmentSideDrawer assessmentCategories={assessmentCategories} />
            <hr className="dividerMobile"/>
            </>
          ) : (
            <div className="sideMenu">
              {assessmentCategories.map((category) => (
                <NavLink
                  key={category}
                  to={`/assessment/${category.replace(/\s+/g, '-')}`}
                  className="navLink"
                >
                  {category}
                </NavLink>
              ))}
            </div>
          )}
          <div className="mainArea">
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}