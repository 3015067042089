import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles } from '@mui/material';
import { createDataForResultsTable } from "../BE/lensRelationalModel";

export default function ResultsTableComparison() {
    const lensCompletionValues = useSelector((state) => state.questionnaire.lensCompletion);
    const lensStrengthValues = useSelector((state) => state.questionnaire.lensStrength);

    const rows = createDataForResultsTable(lensCompletionValues, lensStrengthValues);

    return (
        <div className="lensSummaryTable">
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Lens</TableCell>
                            <TableCell align="right">Completion&nbsp;(%)</TableCell>
                            <TableCell align="right">Strength&nbsp;(%)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.category}>
                                <TableCell component="th" scope="row">
                                    {row.category}
                                </TableCell>
                                <TableCell align="right">{row.completion}</TableCell>
                                <TableCell align="right">{row.strength}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}