//market variables:

export const marketNewnessInfo = {
    name: "marketNewness",
    questionWeights: {
        1: -1,
        3: -1,
        5: 1,
        7: 1,
        9.1: 1,
        26.4: 0.5,
        26.5: 0.5,
    },
    minOutput: -20,
    maxOutput: 40,
    driverQuestions: [5, 7, 9.1],
  };

export const marketCrowdednessInfo = {
    name: "marketCrowdedness",
    questionWeights: {
      1: 1,
      4: -1,
    },
    minOutput: -10,
    maxOutput: 10,
    driverQuestions: [1],
  };

export const marketEvolutionInfo = {
  name: "marketEvolution",
  questionWeights: {
      1: 1,
      2: 1,
      2.1: 1,
  },
  minOutput: 0,
  maxOutput: 30,
  driverQuestions: [1, 2, 2.1,],
};

export const marketReadinessInfo = {
  name: "marketReadiness",
  questionWeights: {
      3: 1,

  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [3],
};

export const marketSubstitutionInfo = {
  name: "marketSubstitution",
  questionWeights: {
      6: -1,
  },
  minOutput: -10,
  maxOutput: 0,
  driverQuestions: [6],
};

export const marketDisruptivenessInfo = {
  name: "marketDisruptiveness",
  questionWeights: {
      5: 1,
      7: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [5, 7],
};



//company variables:

export const companyNewnessInfo = {
  name: "companyNewness",
  questionWeights: {
      9.2: 1,
      14: -1,
      16: -1,
      17: 1,
      20: -1,
      25: -1,
  },
  minOutput: -40,
  maxOutput: 20,
  driverQuestions: [9.2, 17],
};

export const riskToleranceInfo = {
  name: "riskTolerance",
  questionWeights: {
    12: 1,
    13: 1,
    18: -2,
    19: 1,
  },
  minOutput: -20,
  maxOutput: 30,
  driverQuestions: [12, 13, 19],
};

export const frequencyOfInnovationInfo = {
  name: "frequencyOfInnovation",
  questionWeights: {
    15: 1,
    20: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [15, 20],
};

export const typeOfInnovationInfo = {
  name: "typeOfInnovation",
  questionWeights: {
    14: -1,
  },
  minOutput: -10,
  maxOutput: 0,
  driverQuestions: [14,],
};

export const companyKnowledgeInfo = {
  name: "companyKnowledge",
  questionWeights: {
    16: 1,
    20: 1,
    25: 1,
    33: 1,
  },
  minOutput: 0,
  maxOutput: 40,
  driverQuestions: [16, 20, 25, 33],
};

export const companyImpactInfo = {
  name: "companyImpact",
  questionWeights: {
    14: 1,
    22: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [14, 22],
};

  //customer variables:

export const customerProblemInfo = {
  name: "customerProblem",
  questionWeights: {
    8: 1,
    8.1: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [8, 8.1],
};

export const targetCustomersInfo = {
  name: "targetCustomers",
  questionWeights: {
    9: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [9],
};

export const customerFeedbackInfo = {
  name: "customerFeedback",
  questionWeights: {
    10: 1,
    11: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [10, 11],
};

//strategy variables:

export const businessMetricsInfo = {
  name: "businessMetrics",
  questionWeights: {
    23: 1,
    23.1: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [23, 23.1],
};

export const strategyAlignmentInfo = {
  name: "strategyAlignment",
  questionWeights: {
    21: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [21],
};

export const executionResourcesInfo = {
  name: "executionResources",
  questionWeights: {
    24: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [24],
};


//solution

export const conceptDevelopmentInfo = {
  name: "conceptDevelopment",
  questionWeights: {
    26: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [26],
};

export const experienceMapInfo = {
  name: "experienceMap",
  questionWeights: {
    26.1: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [26.1],
};

export const valuePropositionInfo = {
  name: "valueProposition",
  questionWeights: {
    26.2: 1,
    26.5: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [26.2, 26.5],
};

export const strategicDifferentiationInfo = {
  name: "strategicDifferentiation",
  questionWeights: {
    1.1: 1,
    26.4: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [1.1, 26.3],
};

//finance

export const marketSizeInfo = {
  name: "marketSize",
  questionWeights: {
    27: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [27,],
};

export const marketPotentialInfo = {
  name: "marketPotential",
  questionWeights: {
    28: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [28,],
};

export const economyStateInfo = {
  name: "economyState",
  questionWeights: {
    29: 1,
    29.1: 1,
    29.2: 1,
  },
  minOutput: 0,
  maxOutput: 30,
  driverQuestions: [29, 29.1, 29.2],
};

export const investmentSizeInfo = {
  name: "investmentSize",
  questionWeights: {
    30: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [30,],
};


//GTM

export const monetizationInfo = {
  name: "monetization",
  questionWeights: {
    31: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [31,],
};

export const distributionInfo = {
  name: "distribution",
  questionWeights: {
    32: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [32,],
};

export const gtmResourcesInfo = {
  name: "gtmResources",
  questionWeights: {
    33: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [33,],
};

export const marketingInfo = {
  name: "marketing",
  questionWeights: {
    34: 1,
    34.1: 1,
    34.2: 1,
    34.3: 1,

  },
  minOutput: 0,
  maxOutput: 40,
  driverQuestions: [34, 34.1, 34.2, 34.3],
};

export const salesStrategyInfo = {
  name: "salesStrategy",
  questionWeights: {
    35: 1,
    35.1: 1,
    35.2: 1,
    35.3: 1,
  },
  minOutput: 0,
  maxOutput: 40,
  driverQuestions: [35, 35.1, 35.2, 35.3],
};

export const logisticsInfo = {
  name: "logistics",
  questionWeights: {
    35.4: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [35.4,],
};

export const customerCareInfo = {
  name: "customerCare",
  questionWeights: {
    35.5: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [35.5,],
};

//legal & risks

  export const missingBoatRiskInfo = {
    name: "missingBoatRisk",
    questionWeights: {
      1: 1,
      2.1: 1,
      6: 1,
    },
    minOutput: 0,
    maxOutput: 30,
    driverQuestions: [1, 2.1, 6],
  };

export const sinkingBoatRiskInfo = {
  name: "sinkingBoatRisk",
  questionWeights: {
    3: -1,
    7: 1,
    16: 1,
    17: -1,
    20: -1,
  },
  minOutput: -30,
  maxOutput: 20,
  driverQuestions: [7, 16,],
};

export const legalRiskInfo = {
  name: "legalRisk",
  questionWeights: {
    36: 1,
    37: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [36, 37],
};

export const ethicalRiskInfo = {
  name: "ethicalRisk",
  questionWeights: {
    38: 1,
    39: 1,
  },
  minOutput: 0,
  maxOutput: 20,
  driverQuestions: [38, 39],
};

export const legalIPInfo = {
  name: "legalIP",
  questionWeights: {
    40: 1,
  },
  minOutput: 0,
  maxOutput: 10,
  driverQuestions: [40,],
};

