import React from "react";
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import '../styles/ResultsSummary.css';
import { useNavigate } from "react-router-dom";

const categoryLabels = {
    MarketEnvironment: 'Market & Environment',
    Customer: 'Customer',
    Company: 'Company',
    StrategyBusiness: 'Strategy & Business',
    SolutionExperience: 'Solution & Experience',
    Finance: 'Finance',
    GoToMarket: 'Go-To-Market',
    LegalRisk: 'Legal Risk',

  };

export default function ResultsSummary( ) {

    const opportunityName= useSelector(state => state.questionnaire.opportunityData.opportunityName);
    const lensCompletion = useSelector(state => state.questionnaire.lensCompletion);
    const navigate = useNavigate();

    const handleForward = () => {
        navigate("/results/Analysis");
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <h1>Results Summary for: {opportunityName}</h1>
            <h3>Overview:</h3>
            <p>Thank you for completing the questionnaire. We have carefully analyzed your results to summarize them comprehensively. The following sections present a detailed breakdown of your answers across various categories.</p>
            <h3>Calculation method:</h3>
            <p>The results are calculated based on your responses to the questionnaire. Each question contributes to the assessment of different variables, allowing us to provide personalized insights and recommendations tailored to your input.</p>
            <h3>Completion percentage:</h3>
            <p>We've included a completion percentage for each category to offer a snapshot of your engagement with the questionnaire. This percentage reflects the proportion of questions answered within each category, giving you an overview of your involvement in the assessment process.</p>
            <div className="completionContainer">
                {Object.entries(lensCompletion).map(([category, completion]) => (
                <div key={category} className="categoryContainer">
                    <Typography variant="subtitle1" gutterBottom>
                    <strong>{categoryLabels[category]}</strong>
                    </Typography>
                    <LinearProgress variant="determinate" value={completion} />
                    <Typography variant="body2" color="textSecondary">{`${completion}% Completed`}</Typography>
                </div>
                ))}
            </div>
            <h3>Interpretation:</h3>
            <p>While reviewing the results, remember that the insights provided are generated based on your responses. Take some time to reflect on the findings and consider the recommendations (coming soon) provided for each category.</p>
            <div className="navigationButtonContainer">
                <button className="fowardNavButton activeButton singleButton" onClick={handleForward} >Go to Analysis</button>
            </div>
        </div>
    );
    };
    