import React from "react";
import { useParams } from "react-router-dom";
import AssessmentQuestion from "./AssessmentQuestion";
import { questionData } from "../BE/questionsData";
import AssessmentSummary from "./AssessmentSummary";
import AssessmentNavigation from "./AssessmentNavigation";

const AssessmentCategoryContent = () => {
    const { category } = useParams();
    const formattedCategory = category.replace(/-/g, ' ');
    const categoryQuestions = questionData[category] || [];

    return (
        <div>
            <h2>{formattedCategory}</h2>
            {category === "Summary" ? (
                <div>
                    <AssessmentSummary />
                </div>
            ) : (
                // Use a fragment to wrap multiple AssessmentQuestion components
                <>
                    {categoryQuestions.map((question) => (
                        // Place the key prop on the outermost JSX element
                        <div key={question.id}>
                            <AssessmentQuestion question={question} />
                        </div>
                    ))}
                    <AssessmentNavigation />
                </>
            )}
        </div>
    );
};

export default AssessmentCategoryContent;