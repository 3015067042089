import { createSlice } from "@reduxjs/toolkit";

const checkTCSlice = createSlice({
    name: "checkTC",
    initialState: {
        value: false
    },
    reducers: {
        toggleCheckTC: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const {toggleCheckTC} = checkTCSlice.actions;
export default checkTCSlice.reducer;