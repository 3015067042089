export function getWhyInsights(scoreData) {
    const businessMetrics = scoreData.businessMetrics;
    const targetCustomers = scoreData.targetCustomers;
    const customerProblem = scoreData.customerProblem;
    const customerFeedback = scoreData.customerFeedback;
    
    let businessMetricsInsights = {};
    let targetCustomersInsights = {};
    let customerProblemInsights = {};
    let customerFeedbackInsights = {};

    // Business Metrics
    if (businessMetrics === null) {
        businessMetricsInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (businessMetrics < 0.3) {
        businessMetricsInsights = {
            status: "red",
            insights: "Your business metrics score is low. This could mean that you are not clear on the business outcome you want to influence or that your business metrics are not well defined."
        }
    } else if (businessMetrics >= 0.3 && businessMetrics < 0.7) {
        businessMetricsInsights = {
            status: "amber",
            insights: "Your business metrics score is average. This could mean that you have some clarity on the business metrics you want to influence, but there is room for improvement."
        }
    } else if (businessMetrics >= 0.7) {
        businessMetricsInsights = {
            status: "green",
            insights: "Your business metrics score is high. This could mean that you have a clear understanding of the business outcomes and metrics you want to influence."
        }
    }

    // Target Customers
    if (targetCustomers === null) {
        targetCustomersInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (targetCustomers < 0.3) {
        targetCustomersInsights = {
            status: "red",
            insights: "Your target customers score is low. This could mean that you are not clear on who your target customers are or that your target customers are not well defined."
        };
    } else if (targetCustomers >= 0.3 && targetCustomers < 0.7) {
        targetCustomersInsights = {
            status: "amber",
            insights: "Your target customers score is average. This could mean that you have some clarity on who your target customers are, but there is room for improvement."
        };
    } else if (targetCustomers >= 0.7) {
        targetCustomersInsights = {
            status: "green",
            insights: "Your target customers score is high. This could mean that you have a clear understanding of who your target customers are."
        };
    }

    // Customer Problem
    if (customerProblem === null) {
        customerProblemInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (customerProblem < 0.3) {
        customerProblemInsights = {
            status: "red",
            insights: "Your customer problem score is low. This could mean that you are not clear on the customer problem you are trying to solve or that the customer problem is not well defined."
        };
    } else if (customerProblem >= 0.3 && customerProblem < 0.7) {
        customerProblemInsights = {
            status: "amber",
            insights: "Your customer problem score is average. This could mean that you have some clarity on the customer problem you are trying to solve, but there is room for improvement."
        };
    } else if (customerProblem >= 0.7) {
        customerProblemInsights = {
            status: "green",
            insights: "Your customer problem score is high. This could mean that you have a clear understanding of the customer problem you are trying to solve."
        };
    }

    // Customer Feedback
    if (customerFeedback === null) {
        customerFeedbackInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (customerFeedback < 0.3) {
        customerFeedbackInsights = {
            status: "red",
            insights: "Your customer feedback score is low. This could mean that you haven't been able to talk to customers or that it is not easy to capture customer feedback."
        };
    } else if (customerFeedback >= 0.3 && customerFeedback < 0.7) {
        customerFeedbackInsights = {
            status: "amber",
            insights: "Your customer feedback score is average. This could mean that you have some clarity on the customer feedback you have received, but there is room for improvement."
        };
    } else if (customerFeedback >= 0.7) {
        customerFeedbackInsights = {
            status: "green",
            insights: "Your customer feedback score is high. This could mean that you have a clear understanding of the customer feedback."
        };
    }

    return { businessMetricsInsights, targetCustomersInsights, customerProblemInsights, customerFeedbackInsights };
}

//landscape insights

export function getLandscapeInsights(scoreData) {
    const marketNewness = scoreData.marketNewness;
    const companyNewness = scoreData.companyNewness;
    const marketCrowdedness = scoreData.marketCrowdedness;
    const marketEvolution = scoreData.marketEvolution;
    const marketReadiness = scoreData.marketReadiness;
    const marketSubstitution = scoreData.marketSubstitution;
    const marketDisruptiveness = scoreData.marketDisruptiveness;
    const riskTolerance = scoreData.riskTolerance;
    const frequencyOfInnovation = scoreData.frequencyOfInnovation;
    const typeOfInnovation = scoreData.typeOfInnovation;
    const economyState = scoreData.economyState;
    const DegreeOfEntrepreneurship = (riskTolerance + typeOfInnovation) / 2;

    let EOInsights = {};
    let marketNewnessInsights = {};
    let companyNewnessInsights = {};
    let marketCrowdednessInsights = {};
    let marketEvolutionInsights = {};
    let marketReadinessInsights = {};
    let marketSubstitutionInsights = {};
    let marketDisruptivenessInsights = {};
    let riskToleranceInsights = {};
    let frequencyOfInnovationInsights = {};
    let typeOfInnovationInsights = {};
    let economyStateInsights = {};
    let economyStateRiskInsights = {};
    let economyStateMarketInsights = {};


    // EO (Entrepreneurial Orientation)
    if (DegreeOfEntrepreneurship === null || frequencyOfInnovation === null) {
        EOInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else {
        // Cautious
        if (DegreeOfEntrepreneurship < 0.4 && frequencyOfInnovation < 0.5) {
            EOInsights = {
                status: "information",
                insights: "The company type is Cautious. These types of companies usually test and refine existing concepts in small, gradual steps."
            };
        } else if (DegreeOfEntrepreneurship >= 0.4 && DegreeOfEntrepreneurship < 0.6 && frequencyOfInnovation < 0.4) {
            EOInsights = {
                status: "information",
                insights: "The company type is Cautious. These types of companies usually test and refine existing concepts in small, gradual steps."
            };
        }
        // Optimizers
        else if (DegreeOfEntrepreneurship < 0.4 && frequencyOfInnovation >= 0.6) {
            EOInsights = {
                status: "information",
                insights: "The company type is Optimizers. These companies focus on continuously improving existing products or services through small, incremental changes."
            };
        } else if (DegreeOfEntrepreneurship >= 0.4 && DegreeOfEntrepreneurship < 0.6 && frequencyOfInnovation >= 0.6) {
            EOInsights = {
                status: "information",
                insights: "The company type is Optimizers. These companies focus on continuously improving existing products or services through small, incremental changes."
            };
        }
        // Adapters
        else if (DegreeOfEntrepreneurship >= 0.4 && DegreeOfEntrepreneurship < 0.6 && frequencyOfInnovation >= 0.4 && frequencyOfInnovation < 0.6) {
            EOInsights = {
                status: "information",
                insights: "The company type is Adapters. These companies tend to be highly flexible and adaptable, constantly experimenting and innovating based on market changes."
            };
        }
        // Disruptors
        else if (DegreeOfEntrepreneurship >= 0.6 && frequencyOfInnovation < 0.5) {
            EOInsights = {
                status: "information",
                insights: "The company type is Disruptors. These companies tend to introduce major innovations periodically, disrupting the market with new ideas."
            };
        } else if (DegreeOfEntrepreneurship >= 0.5 && DegreeOfEntrepreneurship < 0.6 && frequencyOfInnovation < 0.4) {
            EOInsights = {
                status: "information",
                insights: "The company type is Disruptors. These companies tend to introduce major innovations periodically, disrupting the market with new ideas."
            };
        }
        // Revolutionaries
        else if (DegreeOfEntrepreneurship >= 0.6 && frequencyOfInnovation >= 0.5) {
            EOInsights = {
                status: "information",
                insights: "The company type is Revolutionaries. These companies tend to reimagine entire industries with radical innovations, creating entirely new markets or product categories."
            };
        } else if (DegreeOfEntrepreneurship >= 0.5 && DegreeOfEntrepreneurship < 0.6 && frequencyOfInnovation >= 0.6) {
            EOInsights = {
                status: "information",
                insights: "The company type is Revolutionaries. These companies tend to reimagine entire industries with radical innovations, creating entirely new markets or product categories."
            };
        } else {
            EOInsights = {
                status: "red",
                insights: "We haven't captured enough information to provide insights."
            };
        }
    }


        // Risk Tolerance
    if (riskTolerance === null) {
        riskToleranceInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (riskTolerance < 0.4) {
        riskToleranceInsights = {
            status: "red",
            insights: "Company/Managers prioritize stability and measured growth."
        };
    } else if (riskTolerance >= 0.4 && riskTolerance < 0.8) {
        riskToleranceInsights = {
            status: "amber",
            insights: "Company/Managers are comfortable with calculated risks for new opportunities."
        };
    } else if (riskTolerance >= 0.8) {
        riskToleranceInsights = {
            status: "green",
            insights: "Company/Managers embrace disruption and thrive on innovation."
        };
    }

    // Company Newness
    if (companyNewness === null) {
        companyNewnessInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (companyNewness < 0.4) {
        companyNewnessInsights = {
            status: "information",
            insights: "Your opportunity primarily focuses on refining existing offerings or serving existing markets and customers by leveraging established expertise within the company."
        };
    } else if (companyNewness >= 0.4 && companyNewness < 0.8) {
        companyNewnessInsights = {
            status: "information",
            insights: "Your opportunity could be focusing on new capabilities alongside existing knowledge. You could also be trying to innovate for existing markets or targeting adjacent markets or customers (similar to existing ones but new to the company)."
        };
    } else if (companyNewness >= 0.8) {
        companyNewnessInsights = {
            status: "information",
            insights: "Primarily, you are exploring new and potentially unproven technologies for the company. You could also be trying to target entirely new markets and customers."
        };
    }

    // Market Newness
    if (marketNewness === null) {
        marketNewnessInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (marketNewness < 0.4) {
        marketNewnessInsights = {
            status: "information",
            insights: "The market already has similar products or services, with gradual improvements being the focus."
        };
    } else if (marketNewness >= 0.4 && marketNewness < 0.8) {
        marketNewnessInsights = {
            status: "information",
            insights: "The market offers established solutions, but your opportunity presents a significant innovation compared to existing options."
        };
    } else if (marketNewness >= 0.8) {
        marketNewnessInsights = {
            status: "information",
            insights: "The market currently lacks similar offerings, making your idea entirely new and potentially disruptive."
        };
    }

    // Market Crowdedness
    if (marketCrowdedness === null) {
        marketCrowdednessInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (marketCrowdedness < 0.2) {
        marketCrowdednessInsights = {
            status: "green",
            insights: "Few or no existing competitors."
        };
    } else if (marketCrowdedness >= 0.2 && marketCrowdedness < 0.65) {
        marketCrowdednessInsights = {
            status: "amber",
            insights: "Moderate competition, space is not saturated."
        };
    } else if (marketCrowdedness >= 0.65) {
        marketCrowdednessInsights = {
            status: "red",
            insights: "Highly competitive market with established players."
        };
    }

    // Market Readiness
    if (marketReadiness === null) {
        marketReadinessInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else if (marketReadiness < 0.3) {
        marketReadinessInsights = {
            status: "red",
            insights: "New and potentially disruptive concept, creating a new need."
        };
    } else if (marketReadiness >= 0.3 && marketReadiness < 0.6) {
        marketReadinessInsights = {
            status: "amber",
            insights: "Potential need exists, but may require education or awareness building."
        };
    } else if (marketReadiness >= 0.6) {
        marketReadinessInsights = {
            status: "green",
            insights: "Strong existing need addressed by your solution."
        };
    }


    // Market Evolution
    if (marketEvolution === null) {
        marketEvolutionInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (marketEvolution < 0.3) {
        marketEvolutionInsights = {
            status: "green",
            insights: "Slow pace of change, market is relatively stable."
        } 
    } else if (marketEvolution >= 0.3 && marketEvolution < 0.6) {
        marketEvolutionInsights = {
            status: "amber",
            insights: "Moderate pace of change, requires adaptation but allows for some planning."
        } 
    } else if (marketEvolution >= 0.6) {
        marketEvolutionInsights = {
            status: "red",
            insights: "Fast-paced and constantly evolving market, demanding continuous innovation and agility."
        } 
    }

    // Market Substitution
    if (marketSubstitution === null) {
        marketSubstitutionInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (marketSubstitution < 0.3) {
        marketSubstitutionInsights = {
            status: "green",
            insights: "Limited or difficult to find alternatives for existing solutions."
        } 
    } else if (marketSubstitution >= 0.3 && marketSubstitution < 0.6) {
        marketSubstitutionInsights = {
            status: "amber",
            insights: "Several readily available substitutes exist for similar offerings."
        } 
    } else if (marketSubstitution >= 0.6) {
        marketSubstitutionInsights = {
            status: "red",
            insights: "High availability of alternatives, requiring strong differentiation and unique value proposition."
        } 
    }

    // Market Disruptiveness
    if (marketDisruptiveness === null) {
        marketDisruptivenessInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (marketDisruptiveness < 0.3) {
        marketDisruptivenessInsights = {
            status: "green",
            insights: "Little to no disruption of existing market dynamics."
        } 
    } else if (marketDisruptiveness >= 0.3 && marketDisruptiveness < 0.6) {
        marketDisruptivenessInsights = {
            status: "amber",
            insights: "Offers moderate disruption, potentially improving established solutions."
        } 
    } else if (marketDisruptiveness >= 0.6) {
        marketDisruptivenessInsights = {
            status: "red",
            insights: "Highly disruptive market, requiring constant innovation and agility."
        } 
    }


    //economic state
    if (economyState === null) {
        economyStateInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (economyState < 0.3) {
        economyStateInsights = {
            status: "red",
            insights: "Economic conditions are unfavorable, potentially limiting investment and growth opportunities."
        }
    } else if (economyState >= 0.3 && economyState < 0.6) {
        economyStateInsights = {
            status: "amber",
            insights: "Moderate economic conditions, allowing for some growth and investment opportunities."
        }
    } else if (economyState >= 0.6) {
        economyStateInsights = {
            status: "green",
            insights: "Favorable economic conditions, encouraging growth and investment opportunities."
        }
    }

    //economic state risk
    if (economyState === null || riskTolerance === null) {
        economyStateRiskInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else {
        if (economyState < 0.3) {
            if (riskTolerance < 0.4) {
                economyStateRiskInsights = {
                    status: "red",
                    insights: "The company could focus on essential needs and cost-effectiveness."
                };
            } else if (riskTolerance < 0.8) {
                economyStateRiskInsights = {
                    status: "amber",
                    insights: "The company may consider cost-cutting or delay new ventures."
                };
            } else {
                economyStateRiskInsights = {
                    status: "amber",
                    insights: "The company may consider strategic investments and growth opportunities, but unlikely to pursue high-risk ventures."
                };
            }
        } else if (economyState < 0.6) {
            if (riskTolerance < 0.4) {
                economyStateRiskInsights = {
                    status: "red",
                    insights: "The company could focus on core business."
                };
            } else if (riskTolerance < 0.8) {
                economyStateRiskInsights = {
                    status: "amber",
                    insights: "The company may be cautiously receptive to new opportunities with strong value propositions."
                };
            } else {
                economyStateRiskInsights = {
                    status: "green",
                    insights: "The company may be open to strategic investments and growth opportunities."
                };
            }
        } else { // economyState >= 0.6
            if (riskTolerance < 0.4) {
                economyStateRiskInsights = {
                    status: "amber",
                    insights: "The company may invest cautiously in expansion or new ventures focused on essential needs."
                };
            } else if (riskTolerance < 0.8) {
                economyStateRiskInsights = {
                    status: "green",
                    insights: "The company could actively explore both established and innovative solutions."
                };
            } else {
                economyStateRiskInsights = {
                    status: "green",
                    insights: "The company may invest heavily in expansion and consider high-risk, high-reward opportunities with strong potential."
                };
            }
        }
    }

    //economic state market
    if (economyState === null || marketNewness === null) {
        economyStateMarketInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        };
    } else {
        if (economyState < 0.3) {
            if (marketNewness < 0.4) {
                economyStateMarketInsights = {
                    status: "red",
                    insights: "Customers prioritize essential needs and established brands."
                };
            } else if (marketNewness < 0.8) {
                economyStateMarketInsights = {
                    status: "amber",
                    insights: "Customers may be cautious about adopting new products/services."
                };
            } else {
                economyStateMarketInsights = {
                    status: "amber",
                    insights: "Highly innovative offerings are generally considered high-risk."
                };
            }
        } else if (economyState < 0.6) {
            if (marketNewness < 0.4) {
                economyStateMarketInsights = {
                    status: "amber",
                    insights: "Customers are more open to established brands and familiar products/services."
                };
            } else if (marketNewness < 0.8) {
                economyStateMarketInsights = {
                    status: "amber",
                    insights: "Customers may be receptive to partially new offerings with clear value propositions."
                };
            } else {
                economyStateMarketInsights = {
                    status: "amber",
                    insights: "Customers may be cautiously curious about innovative solutions."
                };
            }
        } else { // economyState >= 0.6
            if (marketNewness < 0.4) {
                economyStateMarketInsights = {
                    status: "amber",
                    insights: "Customers may be more willing to spend on established brands and familiar products/services."
                };
            } else if (marketNewness < 0.8) {
                economyStateMarketInsights = {
                    status: "green",
                    insights: "Customers are generally more open to adopting partially new offerings with clear benefits."
                };
            } else {
                economyStateMarketInsights = {
                    status: "green",
                    insights: "Customers are most receptive to innovative solutions."
                };
            }
        }
    }

    return { EOInsights, marketNewnessInsights, companyNewnessInsights, marketCrowdednessInsights, marketEvolutionInsights, marketReadinessInsights, marketSubstitutionInsights, marketDisruptivenessInsights, riskToleranceInsights, frequencyOfInnovationInsights, typeOfInnovationInsights, economyStateInsights, economyStateRiskInsights, economyStateMarketInsights };

}

//what insights

export function getWhatInsights(scoreData) {

    const strategyAlignmentScore = scoreData.strategyAlignment;
    const conceptDevelopmentScore = scoreData.conceptDevelopment;
    const experienceMapScore = scoreData.experienceMap;
    const strategicDifferentiationScore = scoreData.strategicDifferentiation;
    const valuePropositionScore = scoreData.valueProposition;
    const marketSizeScore = scoreData.marketSize;
    const marketPotentialScore = scoreData.marketPotential;

    let strategyAlignmentInsights = {};
    let conceptDevelopmentInsights = {};
    let experienceMapInsights = {};
    let strategicDifferentiationInsights = {};
    let valuePropositionInsights = {};
    let marketSizeInsights = {};
    let marketPotentialInsights = {};


    // Strategy Alignment
    if (strategyAlignmentScore === null) {
        strategyAlignmentInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (strategyAlignmentScore < 0.3) {
        strategyAlignmentInsights = {
            status: "red",
            insights: "Limited Alignment: Your opportunity may not currently align well with your company's strategic priorities, mission, or risk tolerance."
        }
    } else if (strategyAlignmentScore >= 0.3 && strategyAlignmentScore < 0.7) {
        strategyAlignmentInsights = {
            status: "amber",
            insights: "Moderate Alignment: There is a potential alignment with some aspects of the company's strategy, but further refinement may be needed."
        }
    } else if (strategyAlignmentScore >= 0.7) {
        strategyAlignmentInsights = {
            status: "green",
            insights: "Strong Alignment: Your opportunity is well-aligned with your company's strategic priorities, mission, and risk tolerance."
        }
    }


    // Concept Development
    if (conceptDevelopmentScore === null) {
        conceptDevelopmentInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (conceptDevelopmentScore < 0.3) {
        conceptDevelopmentInsights = {
            status: "red",
            insights: "The core concept may be unclear, lack focus, or not address a readily identified need."
        }
    } else if (conceptDevelopmentScore >= 0.3 && conceptDevelopmentScore < 0.7) {
        conceptDevelopmentInsights = {
            status: "amber",
            insights: "The core concept is emerging but may require further refinement to ensure it addresses a well-defined need and resonates with the target audience."
        }
    } else if (conceptDevelopmentScore >= 0.7) {
        conceptDevelopmentInsights = {
            status: "green",
            insights: "The core concept is well-defined, addresses a clear need, and resonates with the target audience."
        }
    }

    // Experience Map
    if (experienceMapScore === null) {
        experienceMapInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (experienceMapScore < 0.3) {
        experienceMapInsights = {
            status: "red",
            insights: "The user journey is unclear or lacks details, potentially creating friction and hindering user satisfaction."
        }
    } else if (experienceMapScore >= 0.3 && experienceMapScore < 0.7) {
        experienceMapInsights = {
            status: "amber",
            insights: "The user journey is emerging but may require further refinement to ensure a seamless and valuable experience across all touchpoints."
        }
    } else if (experienceMapScore >= 0.7) {
        experienceMapInsights = {
            status: "green",
            insights: "The user journey is well-defined, maps a seamless and valuable experience for users, and anticipates potential touchpoints effectively."
        }
    }

    // Strategic Differentiation
    if (strategicDifferentiationScore === null) {
        strategicDifferentiationInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (strategicDifferentiationScore < 0.3) {
        strategicDifferentiationInsights = {
            status: "red",
            insights: "The solution may lack clear differentiators from existing competitors, potentially hindering its competitive advantage."
        }
    } else if (strategicDifferentiationScore >= 0.3 && strategicDifferentiationScore < 0.7) {
        strategicDifferentiationInsights = {
            status: "amber",
            insights: "The solution demonstrates potential differentiators, but further analysis is needed to solidify and communicate them effectively."
        }
    } else if (strategicDifferentiationScore >= 0.7) {
        strategicDifferentiationInsights = {
            status: "green",
            insights: "The solution possesses clear and compelling differentiators that set it apart from competitors, enhancing its value proposition."
        }
    }


    // Value Proposition
    if (valuePropositionScore === null) {
        valuePropositionInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (valuePropositionScore < 0.3) {
        valuePropositionInsights = {
            status: "red",
            insights: "The value proposition is unclear or fails to resonate with the target audience's needs and desires."
        }
    } else if (valuePropositionScore >= 0.3 && valuePropositionScore < 0.7) {
        valuePropositionInsights = {
            status: "amber",
            insights: "The value proposition is emerging but may require refinement to clearly communicate the benefits and address the key pain points of the target audience."
        }
    } else if (valuePropositionScore >= 0.7) {
        valuePropositionInsights = {
            status: "green",
            insights: "The value proposition is clear, concise, and effectively communicates the unique benefits and value offered to the target audience."
        }
    }

    // Market Size
    if (marketSizeScore === null) {
        marketSizeInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (marketSizeScore < 0.3) {
        marketSizeInsights = {
            status: "red",
            insights: "Limited understanding of the potential market size for your opportunity, potentially impacting resource allocation and strategic planning."
        }
    } else if (marketSizeScore >= 0.3 && marketSizeScore < 0.7) {
        marketSizeInsights = {
            status: "amber",
            insights: "Some understanding of the potential market size is present, but further research and analysis may be necessary to refine the estimate and gain more confidence."
        }
    } else if (marketSizeScore >= 0.7) {
        marketSizeInsights = {
            status: "green",
            insights: "Confident understanding of the potential market size based on reliable data and thorough analysis, enabling informed decision-making."
        }
    }

    // Market Potential
    if (marketPotentialScore === null) {
        marketPotentialInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (marketPotentialScore < 0.3) {
        marketPotentialInsights = {
            status: "red",
            insights: "The perceived market growth potential is low, potentially requiring further exploration of alternative opportunities or strategic adjustments."
        }
    } else if (marketPotentialScore >= 0.3 && marketPotentialScore < 0.7) {
        marketPotentialInsights = {
            status: "amber",
            insights: "The market shows potential for growth, but further analysis is needed to understand the drivers and assess the risks and rewards associated with pursuing this opportunity."
        }
    } else if (marketPotentialScore >= 0.7) {
        marketPotentialInsights = {
            status: "green",
            insights: "The market demonstrates significant potential for growth, presenting an attractive opportunity for expansion and potentially high returns."
        }
    }


    return { strategyAlignmentInsights, conceptDevelopmentInsights, experienceMapInsights, strategicDifferentiationInsights, valuePropositionInsights, marketSizeInsights, marketPotentialInsights };
    
}

//how insights

export function getHowInsights(resultScores) {
    const companyKnowledgeScore = resultScores.companyKnowledge;
    const companyImpactScore = resultScores.companyImpact;
    const monetizationScore = resultScores.monetization;
    const distributionScore = resultScores.distribution;
    const gtmResourcesScore = resultScores.gtmResources;
    const marketingScore = resultScores.marketing;
    const salesStrategyScore = resultScores.salesStrategy;
    const logisticsScore = resultScores.logistics;
    const customerCareScore = resultScores.customerCare;
    const legalRiskScore = resultScores.legalRisk;
    const legalIPScore = resultScores.legalIP;
    const ethicalRiskScore = resultScores.ethicalRisk;
    const investmentSizeScore = resultScores.investmentSize;
    const executionResourcesScore = resultScores.executionResources;

    let companyKnowledgeInsights = {};
    let companyImpactInsights = {};
    let monetizationInsights = {};
    let distributionInsights = {};
    let gtmResourcesInsights = {};
    let marketingInsights = {};
    let salesStrategyInsights = {};
    let logisticsInsights = {};
    let customerCareInsights = {};
    let legalRiskInsights = {};
    let legalIPInsights = {};
    let ethicalRiskInsights = {};
    let investmentSizeInsights = {};
    let executionResourcesInsights = {};

    // Company Knowledge
    if (companyKnowledgeScore === null) {
        companyKnowledgeInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (companyKnowledgeScore < 0.3) {
        companyKnowledgeInsights = {
            status: "red",
            insights: "The company may lack the necessary resources, technology, or expertise to execute the idea, potentially requiring significant investments or partnerships."
        }
    } else if (companyKnowledgeScore >= 0.3 && companyKnowledgeScore < 0.7) {
        companyKnowledgeInsights = {
            status: "amber",
            insights: "The company possesses some relevant knowledge and resources, but further assessment is needed to identify potential gaps in knowledge, skills, or technology. Develop a plan to bridge these gaps before execution, potentially through internal training, external hiring, or partnerships."
        }
    } else if (companyKnowledgeScore >= 0.7) {
        companyKnowledgeInsights = {
            status: "green",
            insights: "The company's existing resources, technology, and expertise position it to effectively execute the idea, reducing potential implementation challenges. This strong internal knowledge base can be leveraged to streamline development and minimize resource allocation needs."
        }
    }

    // Company Impact
    if (companyImpactScore === null) {
        companyImpactInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (companyImpactScore < 0.3) {
        companyImpactInsights = {
            status: "green",
            insights: "The opportunity is expected to have minimal impact on the company's structure, culture, or processes. Existing systems and workflows may be suitable for implementation with minor adjustments."
        }
    } else if (companyImpactScore >= 0.3 && companyImpactScore < 0.7) {
        companyImpactInsights = {
            status: "amber",
            insights: "The opportunity may require adjustments to existing processes or cultural shifts within the company. Analyze potential changes in workflow, communication, or collaboration styles to ensure successful integration of the opportunity."
        }
    } else if (companyImpactScore >= 0.7) {
        companyImpactInsights = {
            status: "red",
            insights: "The opportunity is likely to have a significant impact on the company, requiring substantial planning and change management efforts to adapt the company's structure, culture, and processes. This may involve restructuring teams, implementing new workflows, or fostering a culture of innovation and adaptation."
        }
    }


    // Monetization
    if (monetizationScore === null) {
        monetizationInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (monetizationScore < 0.3) {
        monetizationInsights = {
            status: "red",
            insights: "The chosen monetization strategy may be unclear or lack details, potentially hindering revenue generation and financial sustainability."
        }
    } else if (monetizationScore >= 0.3 && monetizationScore < 0.7) {
        monetizationInsights = {
            status: "amber",
            insights: "A monetization strategy is emerging, but further analysis is needed to refine the pricing model, revenue streams, and their alignment with customer value perception."
        }
    } else if (monetizationScore >= 0.7) {
        monetizationInsights = {
            status: "green",
            insights: "A well-defined monetization strategy is in place, with a clear understanding of pricing, revenue streams, and their alignment with customer value."
        }
    }

    // Distribution
    if (distributionScore === null) {
        distributionInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (distributionScore < 0.3) {
        distributionInsights = {
            status: "red",
            insights: "The distribution channels for reaching the target audience are unclear or lack details, potentially hindering market reach and customer acquisition."
        }
    } else if (distributionScore >= 0.3 && distributionScore < 0.7) {
        distributionInsights = {
            status: "amber",
            insights: "A distribution plan is emerging, but further research and analysis are needed to identify the most effective channels and assess their potential reach and impact."
        }
    } else if (distributionScore >= 0.7) {
        distributionInsights = {
            status: "green",
            insights: "A well-defined distribution strategy is in place, with clear identification of the most suitable channels and a strong understanding of their reach and effectiveness."
        }
    }

    // Marketing
    if (marketingScore === null) {
        marketingInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (marketingScore < 0.3) {
        marketingInsights = {
            status: "red",
            insights: "The marketing approach lacks clarity or details, potentially hindering brand awareness, lead generation, and customer engagement."
        }
    } else if (marketingScore >= 0.3 && marketingScore < 0.7) {
        marketingInsights = {
            status: "amber",
            insights: "A marketing strategy is emerging, but further refinement is needed to define the target audience, messaging, and channels to maximize its effectiveness."
        }
    } else if (marketingScore >= 0.7) {
        marketingInsights = {
            status: "green",
            insights: "A well-defined and comprehensive marketing strategy is in place, with a clear understanding of the target audience, compelling messaging, and a strategic selection of channels to optimize reach, engagement, and lead generation."
        }
    }

    // Sales Strategy
    if (salesStrategyScore === null) {
        salesStrategyInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (salesStrategyScore < 0.3) {
        salesStrategyInsights = {
            status: "red",
            insights: "The method for converting leads into paying customers is unclear or lacks details, potentially hindering successful sales conversion and revenue generation."
        }
    } else if (salesStrategyScore >= 0.3 && salesStrategyScore < 0.7) {
        salesStrategyInsights = {
            status: "amber",
            insights: "A sales approach is emerging, but further analysis is needed to refine the sales process, identify ideal customer profiles, and define effective sales tactics."
        }
    } else if (salesStrategyScore >= 0.7) {
        salesStrategyInsights = {
            status: "green",
            insights: "A well-defined and well-structured sales strategy is in place, with clear understanding of the sales process, ideal customer profiles, and effective sales tactics optimized for conversion."
        }
    }
    // Logistics
    if (logisticsScore === null) {
        logisticsInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (logisticsScore < 0.3) {
        logisticsInsights = {
            status: "red",
            insights: "The solution delivery process to customers is unclear or lacks details, potentially creating inefficiencies and impacting customer satisfaction."
        }
    } else if (logisticsScore >= 0.3 && logisticsScore < 0.7) {
        logisticsInsights = {
            status: "amber",
            insights: "A logistics plan is emerging, but further assessment is needed to define the delivery process, identify potential partners or vendors, and ensure efficient and cost-effective delivery."
        }
    } else if (logisticsScore >= 0.7) {
        logisticsInsights = {
            status: "green",
            insights: "A well-defined and efficient logistics strategy is in place, with clear understanding of the delivery process, established partnerships (if necessary), and a plan for scalability to accommodate future growth."
        }
    }

    // Customer Care
    if (customerCareScore === null) {
        customerCareInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (customerCareScore < 0.3) {
        customerCareInsights = {
            status: "red",
            insights: "The plan for providing ongoing customer support and service is unclear or lacks details, potentially hindering customer satisfaction and loyalty."
        }
    } else if (customerCareScore >= 0.3 && customerCareScore < 0.7) {
        customerCareInsights = {
            status: "amber",
            insights: "A customer care strategy is emerging, but further definition is needed to establish communication channels, define support procedures, and ensure efficient resolution of customer inquiries and issues."
        }
    } else if (customerCareScore >= 0.7) {
        customerCareInsights = {
            status: "green",
            insights: "A well-defined and comprehensive customer care strategy is in place, with clearly defined communication channels, established support procedures, and a focus on proactive customer engagement and satisfaction."
        }
    }

    // GTM Resources
    if (gtmResourcesScore === null) {
        gtmResourcesInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (gtmResourcesScore < 0.3) {
        gtmResourcesInsights = {
            status: "red",
            insights: "The resources available to execute the GTM strategy are unclear or insufficient, potentially hindering successful implementation and achievement of GTM goals."
        }
    } else if (gtmResourcesScore >= 0.3 && gtmResourcesScore < 0.7) {
        gtmResourcesInsights = {
            status: "amber",
            insights: "A resource plan is emerging, but further assessment is needed to identify potential resource gaps and develop a plan to bridge them through internal resource allocation, external partnerships, or additional investment."
        }
    } else if (gtmResourcesScore >= 0.7) {
        gtmResourcesInsights = {
            status: "green",
            insights: "A well-defined and well-resourced GTM plan is in place, with clear allocation of internal and external resources to ensure successful execution of the GTM strategy and achievement of its objectives."
        }
    }

    // Legal Risk
    if (legalRiskScore === null) {
        legalRiskInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (legalRiskScore < 0.3) {
        legalRiskInsights = {
            status: "red",
            insights: "Limited understanding of potential legal risks associated with the opportunity, potentially leading to significant challenges and delays down the line."
        }
    } else if (legalRiskScore >= 0.3 && legalRiskScore < 0.7) {
        legalRiskInsights = {
            status: "amber",
            insights: "Some understanding of potential legal risks exists, but further analysis is needed to identify and mitigate potential legal issues related to regulations, compliance, and intellectual property."
        }
    } else if (legalRiskScore >= 0.7) {
        legalRiskInsights = {
            status: "green",
            insights: "A comprehensive understanding of potential legal risks is established, with a plan in place to mitigate these risks through legal counsel, compliance measures, and strategic decision-making."
        }
    }

    // Ethical Risk
    if (ethicalRiskScore === null) {
        ethicalRiskInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (ethicalRiskScore < 0.3) {
        ethicalRiskInsights = {
            status: "red",
            insights: "Potential ethical concerns associated with the opportunity are unclear or unexplored, potentially leading to reputational damage and negative societal impact."
        }
    } else if (ethicalRiskScore >= 0.3 && ethicalRiskScore < 0.7) {
        ethicalRiskInsights = {
            status: "amber",
            insights: "Some potential ethical concerns are identified, but further exploration is needed to analyze their significance, develop mitigation strategies, and ensure responsible development and implementation."
        }
    } else if (ethicalRiskScore >= 0.7) {
        ethicalRiskInsights = {
            status: "green",
            insights: "A robust ethical framework is established, with clear identification of potential ethical risks, development of comprehensive mitigation strategies that align with ethical principles, and transparent communication with stakeholders."
        }
    }

    // legal IP
    if (legalIPScore === null) {
        legalIPInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (legalIPScore < 0.3) {
        legalIPInsights = {
            status: "red",
            insights: "Potential opportunities to develop intellectual property (IP) are unclear or unexplored, potentially hindering the opportunity's long-term value and competitive advantage."
        }
    } else if (legalIPScore >= 0.3 && legalIPScore < 0.7) {
        legalIPInsights = {
            status: "amber",
            insights: "Some potential areas for IP development are identified, but further assessment is needed to determine the best strategies for protecting and leveraging them."
        }
    } else if (legalIPScore >= 0.7) {
        legalIPInsights = {
            status: "green",
            insights: "A well-defined IP strategy is in place, clearly identifying valuable IP assets, appropriate protection measures (e.g., patents, trademarks, copyrights), and a plan for leveraging IP for future growth and competitive advantage."
        }
    }

    // investment size
    if (investmentSizeScore === null) {
        investmentSizeInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (investmentSizeScore < 0.3) {
        investmentSizeInsights = {
            status: "green",
            insights: "The opportunity requires a relatively low investment, potentially manageable with readily available resources, minimizing the need for significant resource allocation adjustments."
        }
    } else if (investmentSizeScore >= 0.3 && investmentSizeScore < 0.7) {
        investmentSizeInsights = {
            status: "amber",
            insights: "The opportunity requires a moderate level of investment, potentially manageable with existing resources or requiring moderate adjustments to resource allocation plans."
        }
    } else if (investmentSizeScore >= 0.7) {
        investmentSizeInsights = {
            status: "red",
            insights: "The opportunity requires a significant investment, potentially exceeding available resources or requiring external funding. Careful financial planning and resource allocation strategies are crucial."
        }
    }

    // execution resources
    if (executionResourcesScore === null) {
        executionResourcesInsights = {
            status: "red",
            insights: "We haven't captured enough information to provide insights."
        }
    } else if (executionResourcesScore < 0.3) {
        executionResourcesInsights = {
            status: "red",
            insights: "The current availability of resources (e.g., personnel, equipment, budget) is limited, potentially hindering the successful execution of the opportunity. Resource gap analysis and strategic planning to bridge these gaps are crucial."
        }
    } else if (executionResourcesScore >= 0.3 && executionResourcesScore < 0.7) {
        executionResourcesInsights = {
            status: "amber",
            insights: "While some resources are available, further assessment is needed to identify potential gaps and develop a plan to address them through internal reallocation, external partnerships, or resource acquisition."
        }
    } else if (executionResourcesScore >= 0.7) {
        executionResourcesInsights = {
            status: "green",
            insights: "The necessary resources are readily available or easily attainable, enabling efficient execution and reducing implementation timelines and costs."
        }
    }

    return { companyKnowledgeInsights, companyImpactInsights, monetizationInsights, distributionInsights, gtmResourcesInsights, marketingInsights, salesStrategyInsights, logisticsInsights, customerCareInsights, legalRiskInsights, legalIPInsights, ethicalRiskInsights, investmentSizeInsights, executionResourcesInsights };
}

