import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';  // Import the Menu icon

const AssessmentSideDrawer = ({ assessmentCategories }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
    const toggleDrawer = (open) => () => {
      setIsDrawerOpen(open);
    };
  
    return (
      <div className='sideMenu'>
        {/* Text "Lenses:" next to the three-line button */}
        <div>
          <p>Lenses:</p>
        </div>
  
        {/* Hamburger (Menu) icon to open/close the drawer on smaller screens */}
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
  
        {/* Mini variant Drawer component */}
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          variant="temporary"  // Set variant to temporary for mini variant Drawer
          width={64}           // Set the width for mini variant Drawer
        >
          <List>
            {/* Category list */}
            {assessmentCategories.map((category) => (
              <ListItem key={category} button>
                <NavLink
                  to={`/assessment/${category.replace(/\s+/g, '-')}`}
                  className="navLink"
                  onClick={toggleDrawer(false)}
                >
                  {category}
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
    );
};

export default AssessmentSideDrawer;
