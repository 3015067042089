import React from "react";
import { useSelector } from "react-redux";
import { getWhatInsights } from "../BE/variablesRelationalModel";
import { renderTable } from "../BE/displayFunctions";
import { Chart } from "react-google-charts";

export default function WhatAnalysis() {

    const resultScores = useSelector((state) => state.results.scores);

    const {  strategyAlignmentInsights, conceptDevelopmentInsights, experienceMapInsights, strategicDifferentiationInsights, valuePropositionInsights, marketSizeInsights, marketPotentialInsights} = getWhatInsights(resultScores);

    const strategyAlignmentScore = resultScores.strategyAlignment;
    const conceptDevelopmentScore = resultScores.conceptDevelopment;
    const experienceMapScore = resultScores.experienceMap;
    const strategicDifferentiationScore = resultScores.strategicDifferentiation;
    const valuePropositionScore = resultScores.valueProposition;
    const marketSizeScore = resultScores.marketSize;
    const marketPotentialScore = resultScores.marketPotential;


    const strategyAlignmentData = [
        ["Variable", "Score"],
        ["Strategy Alignment", strategyAlignmentScore || 0],
    ]

    const conceptDevelopmentData = [
        ["Variable", "Score"],
        ["Concept Development", conceptDevelopmentScore || 0],
    ]

    const experienceMapData = [
        ["Variable", "Score"],
        ["Experience Map", experienceMapScore || 0],
    ]

    const strategicDifferentiationData = [
        ["Variable", "Score"],
        ["Strategic Differentiation", strategicDifferentiationScore || 0],
    ]

    const valuePropositionData = [
        ["Variable", "Score"],
        ["Value Proposition", valuePropositionScore || 0],
    ]

    const marketSizePotentialData = [
        ["Variable", "Score"],
        ["Market Size", marketSizeScore || 0],
        ["Market Potential", marketPotentialScore],
    ]

    const LineChartOptions = {
        chartArea: { width: "50%" },
        hAxis: {
          title: "Score",
          minValue: 0,
          maxValue: 1,
          ticks: [
            { v: 0, f: 'Low' },
            { v: 0.5, f: 'Medium' },
            { v: 1, f: 'High' },
            
          ],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        vAxis: {
          title: "Variables",
        },
        legend: 'none',
        tooltip: { trigger: 'none' },
      };


    return (
        <div className="whatContainer">
            <h2>What</h2>
            <p>Based on the information you've provided about your desired impact and starting point, let's gain deeper insights into your opportunity. We'll analyze how it aligns with your company's goals, concept, and experience. This analysis will provide a comprehensive picture of your opportunity's potential and build a foundation for its future success.</p>
            <div className="strategyInsights">
                <h3>Strategy Alignment</h3>
                <p>To set your opportunity on the path to success, it's crucial to ensure strategic harmony with your company's overall goals and vision</p>
                <Chart
                    chartType="BarChart"
                    width="100%"
                    data={strategyAlignmentData}
                    options={LineChartOptions}
                />
                {renderTable(strategyAlignmentInsights)}
            </div>

            <div className="SolutionExperienceInsights">
                <h3>Solution & Experience</h3>
                <div className="conceptContainer">
                    <h4>Concept Development</h4>
                    <p>This stage evaluates how developed concepts are. This is a crucial step in understanding the potential of each opportunity before moving forward with detailed planning and execution.</p>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        data={conceptDevelopmentData}
                        options={LineChartOptions}
                    />
                    {renderTable(conceptDevelopmentInsights)}
                </div>
                <div className="experienceMapContainer">
                    <h4>Experience Map</h4>
                    <p>The experience map provides insights into user touchpoints and potential areas for enhancement. It serves as a pivotal step in gaining a deeper understanding of user interactions.</p>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        data={experienceMapData}
                        options={LineChartOptions}
                    />
                    {renderTable(experienceMapInsights)}
                </div>
                <div className="strategicDifferentiatorsContainer">
                    <h4>Strategic Differentiators</h4>
                    <p>This stage evaluates the strength and distinctiveness of the strategic differentiators, offering valuable insights into what makes each opportunity stand out.</p>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        data={strategicDifferentiationData}
                        options={LineChartOptions}
                    />
                    {renderTable(strategicDifferentiationInsights)}
                </div>
                <div className="valuePropositionContainer">
                    <h4>Value Proposition</h4>
                    <p>This stage evaluates how developed concepts are. This is a crucial step in understanding the potential of each opportunity before moving forward with detailed planning and execution.</p>
                    <Chart
                        chartType="BarChart"
                        width="100%"
                        data={valuePropositionData}
                        options={LineChartOptions}
                    />
                    {renderTable(valuePropositionInsights)}
                </div>
            </div>

            <div className="marketSizePotentialContainer">
                <h3>Market Size & Potential</h3>
                <p> These variables are crucial for evaluating your opportunity's potential financial viability and overall attractiveness.</p>
                <Chart
                    chartType="BarChart"
                    width="100%"
                    data={marketSizePotentialData}
                    options={LineChartOptions}
                />
                {renderTable(marketSizeInsights)}
                {renderTable(marketPotentialInsights)}
            </div>
        </div>  
    )
}

