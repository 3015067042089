// ResultsSideDrawer.js

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const ResultsSideDrawer = ({ resultCategories }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <div className='sideMenu'>
      {/* Text "Results:" next to the three-line button */}
      <p>Results menu:</p>

      {/* Hamburger (Menu) icon to open/close the drawer on smaller screens */}
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>

      {/* Mini variant Drawer component */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        variant="temporary"
        width={64}
      >
        <List>
          {/* Category list */}
          {resultCategories.map((category) => (
            <ListItem key={category} button>
              <NavLink
                to={`/results/${category.replace(/\s+/g, '-')}`}
                className="navLink"
                onClick={toggleDrawer(false)}
              >
                {category}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default ResultsSideDrawer;
