export function getLensCompletionInsights(lensCompletionValues) {
    let completedLens = [];
    let lensCompletionWarnings = [];

    const categoryLabels = {
        MarketEnvironment: 'Market & Environment',
        Customer: 'Customer',
        Company: 'Company',
        StrategyBusiness: 'Strategy & Business',
        SolutionExperience: 'Solution & Experience',
        Finance: 'Finance',
        GoToMarket: 'Go-To-Market',
        LegalRisk: 'Legal & Risk',
    
      };

    for (const category in lensCompletionValues) {
        const completionPercentage = lensCompletionValues[category];
        const categoryLabel = categoryLabels[category];

        if (completionPercentage < 60) {
            const warningMessage = `The ${categoryLabel} lens doesn't have enough information`;
            lensCompletionWarnings.push(warningMessage);
        } else {
            completedLens.push(category);
        }
    }

    return { completedLens, lensCompletionWarnings };

};

export function getLensComparisonRisks(lensStrength) {

    let lensComparisonFoundationalRisks = [];
    let lensComparisonProgressiveRisks = [];

    // Solution & Experience
    if (lensStrength.SolutionExperience > lensStrength.MarketEnvironment) {
        lensComparisonFoundationalRisks.push("Solution & Experience Lens seems more developed than the Market & Environment Lens. The market conditions could influence the Solution & Experience Lens.");
    };

    if (lensStrength.SolutionExperience > lensStrength.Customer) {
        lensComparisonFoundationalRisks.push("Solution & Experience Lens seems more developed than the Customer Lens. Changes to the customer lens could influence the Solution & Experience Lens.");
    };

    //Strategy
    if (lensStrength.Strategy > lensStrength.MarketEnvironment) {
        lensComparisonFoundationalRisks.push("Strategy Lens seems more developed than the Market & Environment Lens. The market conditions could influence the Strategy Lens.");
    };

    if (lensStrength.Strategy > lensStrength.Customer) {
        lensComparisonFoundationalRisks.push("Strategy Lens seems more developed than the Customer Lens. The Customer Lens could influence the Strategy Lens.");
    };

    if (lensStrength.Strategy > lensStrength.Company) {
        lensComparisonFoundationalRisks.push("Strategy Lens seems more developed than the Company Lens. The company conditions could influence the Strategy Lens.");
    };

    // GTM

    if (lensStrength.GoToMarket > lensStrength.Strategy) {
        lensComparisonProgressiveRisks.push("Go-To-Market Lens seems more developed than the Strategy & Business Lens. Changes to the strategy could influence the Go-To-Market Lens.");
    };

    if (lensStrength.GoToMarket > lensStrength.SolutionExperience) {
        lensComparisonProgressiveRisks.push("Go-To-Market Lens seems more developed than the Solution & Experience Lens. Changes to the experience or solution could influence the Go-To-Market Lens. ");
    };

    // Finance

    if (lensStrength.Finance > lensStrength.Strategy) {
        lensComparisonProgressiveRisks.push("Finance Lens seems more developed than the Strategy & Business Lens. Changes to the strategy could influence the Finance Lens.");
    };

    if (lensStrength.Finance > lensStrength.SolutionExperience) {
        lensComparisonProgressiveRisks.push("Finance Lens seems more developed than the Solution & Experience Lens. Changes to the experience or solution could influence the Finance Lens.");
    };

    return { lensComparisonFoundationalRisks, lensComparisonProgressiveRisks };

}

function createData(name, completionValue, strengthValue) {
    return { name, completionValue, strengthValue};
}

export function createDataForResultsTable(lensCompletionValues, lensStrengthValues) {
    const categoryLabels = {
        MarketEnvironment: 'Market & Environment',
        Customer: 'Customer',
        Company: 'Company',
        StrategyBusiness: 'Strategy & Business',
        SolutionExperience: 'Solution & Experience',
        Finance: 'Finance',
        GoToMarket: 'Go-To-Market',
        LegalRisk: 'Legal & Risk',
    };

    let rows = [];

    // Iterate over categories
    for (const category in categoryLabels) {
        if (categoryLabels.hasOwnProperty(category)) {
            // Find values for this category
            const completionValue = lensCompletionValues[category];
            const strengthValue = lensStrengthValues[category];

            // Create row object
            const row = {
                category: categoryLabels[category],
                completion: completionValue,
                strength: strengthValue
            };

            // Add row to rows array
            rows.push(row);
        }
    }

    return rows;
}

