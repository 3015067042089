import React from "react";
import ResultsTableComparison from "./ResultsTableComparison";
import RecommendationProcess from "./RecommendationProcess";
import { useNavigate } from "react-router-dom";



import "../styles/Recommendations.css"

export default function Recommendations() {

    const navigate = useNavigate();

    const handleForward = () => {
        navigate("/results/Summary");
    }

    const handleBackward = () => {
        navigate("/results/Analysis");
    }
    
    return(
        <div className="recommendations">
            <h1>Recommendations</h1>

            <p>We analyzed your product idea and uncovered exciting possibilities! This section is your personalized roadmap to turn those possibilities into reality. Based on your answers, we've crafted tailored recommendations for the next steps.</p>

            <p>Here's what you'll find:</p>

            <ul>
                <li><strong>Your Product Scorecard:</strong> Get a quick snapshot of how comprehensively you've answered key questions. The more complete your answers, the more precise our recommendations can be.</li>
                <li><strong>Action Steps Made Easy:</strong> Follow our clear, step-by-step guide to move forward with confidence.</li>
                <li><strong>Potential Challenges & Solutions:</strong> We'll highlight potential roadblocks and equip you with strategies to overcome them.</li>
                <li><strong>Ready-Made Resources:</strong> Save time and effort with our library of templates designed to streamline your product development journey.</li>
            </ul>

            <h2>Questionnaire Summary</h2>
            <p>This section provides a clear picture of how you've filled out the questionnaire. We'll present everything in a handy table, making it easy to see at a glance how much you've completed and the strength of your answers.</p>

            <p>Here's a quick explanation of the table headers:</p>

            <ul>
                <li><strong>Completion (%):</strong> This shows what portion of the questionnaire you've answered. All questions, even those with "I don't know" selections, contribute to completion.</li>
                <li><strong>Strength (%):</strong> This highlights the percentage of questions where you provided specific answers instead of "I don't know." This metric helps us understand how much detailed information you've developed for the category.</li>
            </ul>
            <ResultsTableComparison />

            <h2>Recommended Process</h2>
            <p>Based on the information you provided, we've developed a suggested process tailored to help you nurture and grow this opportunity effectively. This meticulously crafted process considers various variables calculated from the assessment questionnaire.</p>
            <p>Within this framework, each diamond symbolizes a pivotal review session strategically placed at key points along the journey. These review sessions are not just checkpoints but also invaluable opportunities to engage and align with key stakeholders. By doing so, we ensure that every step forward is not only meticulously planned but also perfectly aligned with your objectives.</p>
            <RecommendationProcess />

            <h2>Activities for the Discovery Process</h2>

            <p>Throughout the Product Discovery process, each stage presents unique challenges and opportunities. By carefully selecting and implementing these recommended activities, you can systematically uncover valuable insights, validate assumptions, and iterate on ideas to create innovative and impactful solutions, thereby adding significant value to the process.</p>

            <p>From initial ideation to market launch and beyond, these activities are invaluable tools for fostering collaboration and creativity and ensuring team alignment. They empower you to make informed decisions, prioritize effectively, and focus on delivering value to your customers.</p>

            <img src="/recommendations/Discovery-Activities.webp" className="processImage" alt="recommended activities for the discovery process"></img>

            <div class="templates-section">
                <h2>Templates</h2>
                <p>You can find resources to streamline your strategic planning and development process. Whether you're a startup entrepreneur or a seasoned business professional, our templates are designed to help you navigate complex challenges and drive meaningful outcomes.</p>

                <p>Our current offerings include:</p>
                <ul>
                    <li>
                        <strong>Defining Outcomes:</strong> Establish clear and measurable objectives to guide your strategic endeavors.
                    </li>
                    <li>
                        <strong>Developing Customer Segments:</strong> Identify and understand your target audience to tailor your offerings effectively.
                    </li>
                    <li>
                        <strong>Market Research:</strong> Gather valuable insights to inform your strategic decisions and market positioning.
                    </li>
                    <li>
                        <strong>Developing Value Proposition:</strong> Craft compelling value propositions that resonate with your target market.
                    </li>
                    <li>
                        <strong>Drafting Vision Narratives:</strong> Articulate your vision and aspirations to align your team and stakeholders.
                    </li>
                    <li>
                        <strong>Developing Concepts and Experience Maps:</strong> Visualize your ideas and map out user experiences to drive innovation and differentiation.
                    </li>
                </ul>

                <p>Stay tuned for upcoming additions to our template library, including:</p>
                <ul>
                    <li>Company Analysis</li>
                    <li>Economic Analysis</li>
                    <li>Strategy Development</li>
                    <li>Finance and Business Cases</li>
                    <li>Go-To-Market (GTM) Strategies</li>
                    <li>Capturing User Feedback and Insights</li>
                </ul>
                <p><strong>Note:</strong>You will be redirected to Gumroad to subscribe to our content</p>
                <button className="fowardNavButton activeButton subscribe" onClick={() => window.open('https://rafaeljschwartz.gumroad.com/l/uwowm', '_blank')}>Subscribe now!</button>
            </div>
            
            <hr className="divider" />

            <div className="navigationButtonContainer">

                <button className="backwardButton inactiveButton" onClick={handleBackward}>Back to Analysis</button>
                <button className="fowardNavButton activeButton" onClick={() => window.open('https://byrjs.blog/index.php/contact/', '_blank')}>Contact us</button>

            </div>
        </div>
        
    )
};