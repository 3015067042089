import React from "react";
import '../styles/Footer.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

export default function Footer() {
    return (
        <div className="footerContainer">
            <div className="legalContainer">
                <p className="copyright">Prodivio & RJS © 2024. All rights reserved.</p>
            </div>
            <div className="socialContainer">
                <p>LinkedIn:</p>
                <a href="https://www.linkedin.com/in/rafaeljschwartz/" target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon className="linkedinIcon" style={{color: "white"}} />
                </a>
                <p>Website:</p>
                <a href="https://byrjs.blog" target="_blank" rel="noopener noreferrer">
                    <OpenInBrowserIcon className="rjsIcon" style={{color: "white"}} />
                </a>
            </div>
        </div>
    )
}