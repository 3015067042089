import React from "react";
import { useSelector } from "react-redux";
import { getWhyInsights } from "../BE/variablesRelationalModel";
import {renderTable} from "../BE/displayFunctions";
import {Chart} from "react-google-charts";

export default function WhyAnalysis() {

    const resultScores = useSelector((state) => state.results.scores);

    const { businessMetricsInsights, targetCustomersInsights, customerProblemInsights, customerFeedbackInsights } = getWhyInsights(resultScores);
    const businessMetricsScore = resultScores.businessMetrics;
    const targetCustomersScore = resultScores.targetCustomers;
    const customerProblemScore = resultScores.customerProblem;
    const customerFeedbackScore = resultScores.customerFeedback;

    const businessData = [
        ["Variable", "Score"],
        ["Business Metrics", businessMetricsScore || 0],
    ]

    const customerData = [
        ["Variable", "Score"],
        ["Target Customers", targetCustomersScore || 0],
        ["Customer Problem", customerProblemScore],
        ["Customer Feedback", customerFeedbackScore],
    ]

    const LineChartOptions = {
        chartArea: { width: "50%" },
        hAxis: {
          title: "Score",
          minValue: 0,
          maxValue: 1,
          ticks: [
            { v: 0, f: 'Low' },
            { v: 0.5, f: 'Medium' },
            { v: 1, f: 'High' },
            
          ],
          textStyle: {
            fontSize: 10, // Adjust the font size for ticks
            color: "#767676",
          },
        },
        vAxis: {
          title: "Variables",
        },
        legend: 'none',
        tooltip: { trigger: 'none' },
      };

    return (
        <div className="whyContainer">
            <h2>Why</h2>
            <p>Every great innovation starts with a clear goal. Understanding what you want to achieve with your project and who you're trying to help is like having a roadmap for success.</p>
            <h3>Business Outcomes</h3>
            <div className="businessMetricsInsightsContainer">
                <div className="businessMetricsChart">
                <Chart chartType="BarChart" width="100%" data={businessData} options={LineChartOptions} />
                </div>
                {renderTable(businessMetricsInsights)}
            </div>
            <h3>Customers</h3>
            <div className="businessMetricsChart">
                <Chart chartType="BarChart" width="100%" data={customerData} options={LineChartOptions} />
            </div>
            <div className="targetCustomersInsightsContainer sideBySideInsight" >
                <h4>Target Customers</h4>
                {renderTable(targetCustomersInsights)}
            </div>
            <div className="customerProblemInsightsContainer sideBySideInsight">
                <h4>Customer Problem</h4>
                {renderTable(customerProblemInsights)}
            </div>
            <div className="customerFeedbackInsightsContainer sideBySideInsight">
                <h4>Customer Feedback</h4>
                {renderTable(customerFeedbackInsights)}
            </div>
        </div>
    );
}