import Papa from 'papaparse';

export const questionData = {
    // using the categoy names that are being read by useParams in the AssessmentCategoryContent.js
    "Market-&-Environment": [
        {
            id: 1,
            question: "Are there competitors offering something similar to the opportunity you want to develop?",
            labels: ["I don't know", "Very few competitors", "A lot of competitors"],
            variables: ["marketNewness", "marketCrodedness", "marketEvolution", "missingBoatRisk"],
            followUpQuestion: [
                {
                    id: 1.1,
                    question: "Do you know your competitors' strengths and weaknesses?",
                    labels: ["I don't know them", "Vaguely", "Very high"],
                    variables: ["strategicDifferentiation"],
                },
                {
                    id: 1.2,
                    question: "How fast are competitors delivering new products / new features?",
                    labels: ["I don't know", "Very slow", "Very fast"],
                    variables: ["marketDisruptiveness"],
                }
            ]
        },
        {
            id: 2,
            question: "Do you know what are the emerging trends of the market?",
            labels: ["I don't know", "Vaguely", "Very well"],
            variables: ["marketEvolution"],
            followUpQuestion: [
                {
                    id: 2.1,
                    question: "How fast do you expect these trends to influence the market?",
                    labels: ["I don't know", "Very slow", "Very fast"],
                    variables: ["marketEvolution", "missingBoatRisk"],
                }
            ]
        },
        {
            id: 3,
            question: "How ready is the market for this idea? Is there an existing demand or a growing trend that supports its potential success?",
            labels: ["I don't know", "Not ready", "Very ready"],
            variables: ["marketNewness", "marketReadiness", "sinkingBoatRisk"],
        },
        {
            id: 4,
            question: "Do you see a gap in the market?",
            labels: ["I don't know", "Not clearly", "Very clearly"],
            variables: ["marketCrodedness"],
        },
        {
            id: 5,
            question: "Does the opportunity create a new market segment or category?",
            labels: ["I don't know", "Low potential", "High potential"],
            variables: ["marketNewness","marketDisruptiveness"],
        },
        {
            id: 6,
            question: "How easily can customers substitute products / services in this space?",
            labels: ["I don't know", "Very easy", "Very difficult"],
            variables: ["marketSubstitution", "missingBoatRisk"],
        },
        {
            id: 7,
            question: "To what extent can the idea disrupt existing business models, industry practices, or customer behaviors?",
            labels: ["I don't know", "Low disruption", "Very disruptive"],
            variables: ["marketNewness", "marketDisruptiveness", "sinkingBoatRisk"],
        },

    ],

    "Customer": [
        {
            id: 8,
            question: "Can you clearly state what customer problem or pain point you are trying to solve?",
            labels: ["I don't know", "Not very well", "Very clearly"],
            variables: ["customerProblem"],
            followUpQuestion: [
                {
                    id: 8.1,
                    question: "Do you know your target customers' unmet needs and pain points?",
                    labels: ["I don't know them", "Not very well", "Very well"],
                    variables: ["customerProblem"],
                },
            ]
        },
        {
            id: 9,
            question: "Do you know your target customers / user personas?",
            labels: ["I don't know", "Vaguely", "Very well"],
            variables: ["targetCustomers"],
            followUpQuestion: [
                {
                    id: 9.1,
                    question: "Are your target customers / user personas already using a product or service to solve their problem?",
                    labels: ["I don't know", "Yes", "No"],
                    variables: ["marketNewness"],
                },
                {
                    id: 9.2,
                    question: "Are they existing or new customers of your company?",
                    labels: ["I don't know", "Existing customers", "New customers"],
                    variables: ["companyNewness"],
                },
            ]
        },
        {
            id: 10,
            question: "Do you have the possibility to talk to customers often?",
            labels: ["I don't know", "It's very hard", "Very easily"],
            variables: ["customerFeedback"],
        },
        {
            id: 11,
            question: "Do you already collect user data?",
            labels: ["I don't know", "Not very well", "Very well"],
            variables: ["customerFeedback"],
        },
    ],

    "Company": [
        {
            id: 12,
            question: "How comfortable is the company with taking risks associated with implementing new ideas?",
            labels: ["I don't know", "Uncomfortable", "Very comfortable"],
            variables: ["riskTolerance"],
        },
        {
            id: 13,
            question: "Is there a culture that encourages and supports innovation?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["riskTolerance"],
        },
        {
            id: 14,
            question: "Is this opportunity new to the company, or are you enhancing existing products, services, or operations?",
            labels: ["I don't know", "New to the company", "Similar products exist"],
            variables: ["companyImpact", "companyNewness", "typeOfInnovation"],
        },
        {
            id: 15,
            question: "How often does the company release new products or services?",
            labels: ["I don't know", "Not very often", "Very often"],
            variables: ["frequencyOfInnovation"],
            followUpQuestion: [
                {
                    id: 15.1,
                    question: "How would you classify those new products or services? (incremental to existing products or radical new ideas)",
                    labels: ["I don't know", "Incremental", "Radical"],
                    variables: ["typeOfInnovation"],
                }
            ]
        },       
        {
            id: 16,
            question: "How familiar is the company with similar ideas or opportunities?",
            labels: ["I don't know", "Not very familiar", "Very familiar"],
            variables: ["companyNewness", "sinkingBoatRisk", "companyKnowledge"],
        },
        {
            id: 17,
            question: "Are you exploring technologies that are new to the company?",
            labels: ["I don't know", "Not at all", "Very new to the company"],
            variables: ["companyNewness", "sinkingBoatRisk"],
        },
        {
            id: 18,
            question: "How risk-averse are your managers / leadership team?",
            labels: ["I don't know", "Very tolerant towards risk", "Very risk averse"],
            variables: ["riskTolerance"],
        },
        {
            id: 19,
            question: "What does the company value more, financial results or customer experiences?",
            labels: ["I don't know", "Financial results", "Customer experiences"],
            variables: ["riskTolerance"],

        },
        {
            id: 20,
            question: "Have similar concepts been implemented or explored within the company before?",
            labels: ["I don't know", "Not at all", "Very often"],
            variables: ["companyNewness", "frequencyOfInnovation", "sinkingBoatRisk", "companyKnowledge"],
        }
    ],

    "Strategy-&-Business": [

        {
            id: 21,
            question: "How well does the idea align with the company's vision, mission, and strategic goals?",
            labels: ["I don't know", "Not at all", "Very well"],
            variables: ["strategyAlignment"],
        },
        {
            id: 22,
            question: "How will the idea impact the company's structure, processes, or culture?",
            labels: ["I don't know", "Low impact", "High impact"],
            variables: ["companyImpact"],
        },
        {
            id: 23,
            question: "Do you know what business outcome you want to influence with this idea?",
            labels: ["I don't know", "Not very well", "Very well"],
            variables: ["businessMetrics"],
            followUpQuestion: [
                {
                    id: 23.1,
                    question: "Do you know what metrics you are trying to influence?",
                    labels: ["I don't know", "Not very well", "Very well"],
                    variables: ["businessMetrics"],
                }
            ]
        },
        {
            id: 24,
            question: "Are the necessary resources (financial, human, technological) readily available within the company to develop the idea?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["executionResources"],
        },
        {
            id: 25,
            question: "Does the company possess the required expertise or knowledge to execute the idea successfully?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["companyNewness", "companyKnowledge"],
        }
    ],

    "Solution-&-Experience": [
        {
            id: 26,
            question: "Have you developed a concept or solution for this opportunity?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["conceptDevelopment"],
            followUpQuestion: [
                {
                    id: 26.1,
                    question: "Have you mapped the end-to-end experience of the idea?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["experienceMap"],
                },
                {
                    id: 26.2,
                    question: "Have you developed the value proposition for the idea?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["valueProposition"],
                },
                {
                    id: 26.3,
                    question: "Will customers interact differently with the product/service/process? Are you setting a new paradigm?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["marketNewness"],
                },
                {
                    id: 26.4,
                    question: "To what extent does the idea differentiate itself from competitors regarding features, benefits, or value proposition?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["marketNewness", "strategicDifferentiator"],
                },
                {
                    id: 26.5,
                    question: "Do you know the features and benefits that customers value most?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["valueProposition"],
                },
            ]
        }
    ],

    "Finance": [
        {
            id: 27,
            question: "Do you know the total size of the target market for your concept?",
            labels: ["I don't know", "Not very well", "Very well"],
            variables: ["marketSize"],
        },
        {
            id: 28,
            question: "Is the market growing, shrinking, or remaining stable?",
            labels: ["I don't know", "Shrinking", "Growing"],
            variables: ["marketPotential"],
        },
        {
            id: 29,
            question: "What is the current state of the economy?",
            labels: ["I don't know", "Very bad", "Very good"],
            variables: ["economyState"],
            followUpQuestion: [
                {
                    id: 29.1,
                    question: "How are economic factors likely to impact consumer spending habits?",
                    labels: ["I don't know", "Very negatively", "Very positively"],
                    variables: ["economyState"],
                },
                {
                    id: 29.2,
                    question: "Are there any risks associated with economic volatility?",
                    labels: ["I don't know", "Very high", "Very low"],
                    variables: ["economyState"],
                }
            ]
        },
        {
            id: 30,
            question: "How much capital / investment will you need to develop the opportunity?",
            labels: ["I don't know", "Very low", "Very high"],
            variables: ["investmentSize"],
        }
    ],

    "Go-To-Market": [
        {
            id: 31,
            question: "Have you developed the overall pricing strategy (premium, competitive, value-based)?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["monetization"],
        },
        {
            id: 32,
            question: "Do you know the key distribution channels (direct sales, online, channel partners)?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["distribution"],
        },
        {
            id: 33,
            question: "Do you know the resources you need to execute your Go-To-Market strategy?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["companyKnowledge", "gtmResources"],
        },
        {
            id: 34,
            question: "Have you developed your marketing strategy?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["marketing"],
            followUpQuestion: [
                {
                    id: 34.1,
                    question: "Have you developed your brand positioning and messaging?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: [],
                },
                {
                    id: 34.2,
                    question: "Have you developed the tone and voice you will use in your marketing materials?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: [],
                },
                {
                    id: 34.3,
                    question: "Do you know what channels you will use to reach your target audience with your message?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: [],
                },
            ]
        },
        {
            id: 35,
            question: "Have you developed your sales strategy?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["salesStrategy"],
            followUpQuestion: [
                {
                    id: 35.1,
                    question: "Have you developed a sales process for each market you are trying to target?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["salesStrategy"],
                },
                {
                    id: 35.2,
                    question: "Do you know kind of sales team do you need to build?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["salesStrategy"],
                },
                {
                    id: 35.3,
                    question: "Do you know how to attract and retain channel partners",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["salesStrategy"],
                },
                {
                    id: 35.4,
                    question: "Have you developed your logistics and fulfillment plans?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["logistics"],
                },
                {
                    id: 35.5,
                    question: "Do you know how you will ensure customer satisfaction and after-sales support?",
                    labels: ["I don't know", "Not at all", "Very much"],
                    variables: ["customerCare"],
                }
            ]
        }

    ],

    "Legal-&-Risk": [
        {
            id: 36,
            question: "Have you analyzed the the potential risks and challenges associated with launching your idea?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["legalRisk"],
        },
        {
            id: 37,
            question: "Have you analyzed regulations or legal constraints that could impact your product?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["legalRisk"],
        },
        {
            id: 38,
            question: "Are there any ethical considerations related to your product?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["ethicalRisk"],
        },
        {
            id: 39,
            question: "Are there any cultural or societal factors that might hinder its acceptance?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["ethicalRisk"],
        },
        {
            id: 40,
            question: "Have you explored the feasibility of protecting your unique features through intellectual property (IP) mechanisms like patents, trademarks, or trade secrets?",
            labels: ["I don't know", "Not at all", "Very much"],
            variables: ["legalIP"],
        }
    ],
}


export const exportToCSV = () => {
    // Flatten the nested structure and create an array of objects for CSV
    const flatData = [];
    Object.keys(questionData).forEach(category => {
        questionData[category].forEach(questionObj => {
            flatData.push({
                Category: category,
                ID: questionObj.id,
                Question: questionObj.question,
                Labels: questionObj.labels.join(','),
            });

            if (questionObj.followUpQuestion) {
                questionObj.followUpQuestion.forEach(followUpObj => {
                    flatData.push({
                        Category: category,
                        ID: followUpObj.id,
                        Question: followUpObj.question,
                        Labels: followUpObj.labels.join(','),
                    });
                });
            }
        });
    });

    // Convert the flat data array to CSV using PapaParse
    const csv = Papa.unparse(flatData);

    // Create a Blob and download it as a CSV file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = 'questionData.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};