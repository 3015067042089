import React from "react";
import { useSelector } from "react-redux";
import { getLensCompletionInsights, getLensComparisonRisks } from "../BE/lensRelationalModel";
import { renderTableFromArray, renderRisksTableFromArray } from "../BE/displayFunctions";




export default function RiskAnalysis() {

    const lensCompletionValues = useSelector((state) => state.questionnaire.lensCompletion);
    const lensStrength = useSelector((state) => state.questionnaire.lensStrength);

    const { lensCompletionWarnings } = getLensCompletionInsights(lensCompletionValues);
    const { lensComparisonFoundationalRisks, lensComparisonProgressiveRisks } = getLensComparisonRisks(lensStrength);

    return (
        <div className="risksContainer">
            <h2>Risks</h2>
            <p>It's crucial to acknowledge the potential challenges that might arise along the way. This section delves into the identified risks from your questionnaire, providing you with a clear understanding of potential roadblocks.</p>
            <div className="completionWarningContainer">
                <h4>Completion Warnings</h4>
                <p>These are the areas in your questionnaire that need more information to provide a complete picture of your opportunity.</p>
                <div className="lensCompletionWarningContainer">
                    {renderTableFromArray(lensCompletionWarnings)}
                </div>  
            </div>
            <div className="lensStrengthContainer">
                <h4>Lens Strength</h4>
                <div className="riskExplanation">
                        <p>We can identify two types of risks from the questionnaire. These risks stem from areas within the questionnaire where information may be lacking, or responses indicate uncertainty with answers such as "I don't know."</p>
                        <ul>
                            <li>
                                <strong>Foundational Risks:</strong> As the name implies, foundational risks pertain to critical aspects of the project's foundation. They address fundamental information crucial for decision-making in the early stages of a project. Identifying and addressing foundational risks early on is essential for establishing a solid groundwork and ensuring the project's success as it progresses.
                            </li>
                            <li>
                                <strong>Progressive Risks:</strong> In contrast, progressive risks may not manifest immediately but can potentially impact the project later in its development lifecycle. These risks evolve and may arise from factors such as changing market conditions, evolving stakeholder requirements, or unforeseen technical challenges. It's important to monitor and mitigate progressive risks to safeguard the project's long-term success and resilience against potential disruptions.
                            </li>
                        </ul>
                </div>
                <div className="lensStrengthRisks">
                    {renderRisksTableFromArray(lensComparisonFoundationalRisks, 'Foundational')}
                    {renderRisksTableFromArray(lensComparisonProgressiveRisks, 'Progressive')}
                </div>
            </div>
        </div>
    )
}