import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

const renderIcon = (status) => {
    switch (status) {
        case 'green':
            return <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} className='tableIcon'/>;
        case 'amber':
            return <WarningIcon style={{ color: 'e69b00', fontSize: 20 }} className='tableIcon' />;
        case 'red':
            return <DangerousIcon style={{ color: 'red', fontSize: 20 }} className='tableIcon'/>;
        case 'information':
            return <LightbulbIcon style={{ color: '0055b3', fontSize: 20 }} className='tableIcon'/>;
        default:
            return null;
    }
};

export const renderTable = (insights) => {
    // Check if insights is an array
    if (!Array.isArray(insights)) {
        // Render a single row if it's a single insight object
        return (
            <table className='insightTable'>
                <tbody>
                    <tr>
                        <td className='iconRow'>{renderIcon(insights.status)}</td>
                        <td>{insights.insights}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    return (
        <table className='insightTable'>
            <tbody>
                {insights.map((insight, index) => (
                    <tr key={index}>
                        <td className='iconRow'>{renderIcon(insight.status)}</td>
                        <td>{insight.insights}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export function renderTableFromArray(insights) {
    return (
        <>
            {insights.length > 0 ? (
                <div className="lensCompletionWarning">
                    <table className='insightTable'>
                        <tbody>
                            {insights.map((warning, index) => (
                                <tr key={index} className="completionWarning">
                                    <td className='iconRow'>{renderIcon('amber')}</td>
                                    <td>{warning}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div className="lensCompletionSuccess">
                    <table className='insightTable'>
                        <tbody>
                            <tr>
                                <td className='iconRow'>{renderIcon('green')}</td>
                                <td>Enough information is recorded</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export function renderRisksTableFromArray(risks, type) {
    return (
        <>
        <h5>{type} Risks</h5>
        {risks.length > 0 ? (
            <table className='insightTable'>
                <tbody>
                    {risks.map((risk, index) => (
                        <tr key={index} className="strengthRisk">
                            <td className='iconRow'>
                                {renderIcon('red')}
                            </td>
                            <td>{risk}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <div className="noRisksMessage">
                <table className='insightTable'>
                    <tbody>
                        <tr>
                            <td className='iconRow'>
                                {renderIcon('green')}
                            </td>
                            <td>No {type} risks found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )}
    </>
    );
}
