import React from "react";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

import '../styles/ResultAnalysis.css';
import WhyAnalysis from "./WhyAnalysis";
import LandscapeAnalysis from "./LandscapeAnalysis";
import RiskAnalysis from "./RiskAnalysis";
import WhatAnalysis from "./WhatAnalysis";
import HowAnalysis from "./HowAnalysis";


export default function ResultsAnalysis() {

    const navigate = useNavigate();

    const handleForward = () => {
        navigate("/results/Recommendations");
    }

    const handleBackward = () => {
        navigate("/results/Summary");
    }

    return (
        <div className="resultsContainer">
            <div className="resultsWelcomeContainer">
                <h1>Results Analysis</h1>
                <p>Here, we summarize your questionnaire responses to give you a clear picture of your project. In the upcoming sections, we'll explore different areas based on your answers, providing straightforward insights. Think of it as a guide to understanding where you stand. </p>
                <p>Let's dive in and uncover the valuable information your responses reveal!</p>
            </div>
            <hr className="divider" />

            <WhyAnalysis />
            <hr className="divider" />
            <LandscapeAnalysis />
            <hr className="divider" />
            <WhatAnalysis />
            <hr className="divider" />

            <HowAnalysis />
            <hr className="divider" />

            <RiskAnalysis />

            <hr className="divider" />

            <div className="navigationButtonContainer">

                <button className="backwardButton inactiveButton" onClick={handleBackward}>Back to summary</button>
                <button className="fowardNavButton activeButton" onClick={handleForward}>Recommendations</button>

            </div>

        </div>
    )
}