import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setScore } from "../features/Results/resultsSlice";
import ResultsSideDrawer from "../components/ResultsSideDrawer";


//importing calculation functions
import { calculateGenericScore } from "../BE/scoringModel";

//importing variables info

import * as variablesInfo from "../BE/variablesInfo";

const variables = [
    variablesInfo.marketNewnessInfo,
    variablesInfo.marketCrowdednessInfo,
    variablesInfo.marketEvolutionInfo,
    variablesInfo.marketReadinessInfo,
    variablesInfo.marketSubstitutionInfo,
    variablesInfo.marketDisruptivenessInfo,
    variablesInfo.companyNewnessInfo,
    variablesInfo.riskToleranceInfo,
    variablesInfo.frequencyOfInnovationInfo,
    variablesInfo.typeOfInnovationInfo,
    variablesInfo.missingBoatRiskInfo,
    variablesInfo.sinkingBoatRiskInfo,
    variablesInfo.companyKnowledgeInfo,
    variablesInfo.companyImpactInfo,
    variablesInfo.customerProblemInfo,
    variablesInfo.targetCustomersInfo,
    variablesInfo.customerFeedbackInfo,
    variablesInfo.businessMetricsInfo,
    variablesInfo.strategyAlignmentInfo,
    variablesInfo.conceptDevelopmentInfo,
    variablesInfo.experienceMapInfo,
    variablesInfo.strategicDifferentiationInfo,
    variablesInfo.valuePropositionInfo,
    variablesInfo.marketSizeInfo,
    variablesInfo.marketPotentialInfo,
    variablesInfo.monetizationInfo,
    variablesInfo.distributionInfo,
    variablesInfo.gtmResourcesInfo,
    variablesInfo.marketingInfo,
    variablesInfo.salesStrategyInfo,
    variablesInfo.logisticsInfo,
    variablesInfo.customerCareInfo,
    variablesInfo.legalRiskInfo,
    variablesInfo.legalIPInfo,
    variablesInfo.ethicalRiskInfo,
    variablesInfo.investmentSizeInfo,
    variablesInfo.executionResourcesInfo,
    variablesInfo.economyStateInfo,
  ];

const assessmentCategories = ['Summary', 'Analysis', 'Recommendations'];

export default function ResultsCenter() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const checkTC = useSelector((state) => state.checkTC.value);
    const questionsData = useSelector((state) => state.questionnaire.questionValues);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    useEffect(() => {
      if (!checkTC) {
        navigate('/');
      }
  
      variables.forEach((variable) => {
        const score = calculateGenericScore(questionsData, variable);
        dispatch(setScore({ variable: variable.name, score }));
      });
  
      const pathname = location.pathname;
      document.title = `Prodivio Forge - Results: ${getTitleFromPathname(pathname)}`;
    }, [checkTC, navigate, questionsData, dispatch, location]);
  
    const getTitleFromPathname = (pathname) => {
      return pathname.replace(/^\/results\//, '').replace(/-/g, ' ');
    };
  
    return (
      <div className="appContainer">
        <Header />
        <div className="screenContainer">
          <div className="navigationContainer">
            <img src="/P-Forge.webp" alt="Prodivio Forge Logo" className="pForge-logo" />
            <NavLink to='/assessment/Summary' className={`navLink ${location.pathname.startsWith('/assessment') ? 'activeAssessment' : ''}`} >Assessment</NavLink>
            <NavLink to='/results/Summary' className="navLink">Results</NavLink>
            <div className="emptyNavSpace"></div>
          </div>
          <div className="mainContainer">
            {isMobile ? (
                <>
                <ResultsSideDrawer resultCategories={assessmentCategories} />
                <hr className="dividerMobile"/>
                </>
            ) : (
                <div className="sideMenu">
                {assessmentCategories.map((category) => (
                    <NavLink 
                        key={category} 
                        to={`/results/${category.replace(/\s+/g, '-')}`}
                        className="navLink"
                    >
                    {category}
                    </NavLink>
                ))} 
            </div>
            )}
            <div className="mainArea">
              <Outlet />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

