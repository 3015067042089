import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCheckTC } from '../features/checkTC/checkTCSlice';
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";

//import styles
import '../styles/WelcomeScreen.css'
import '../styles/NavigationButton.css'

//import components
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function WelcomeScreen() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checkTC = useSelector((state) => state.checkTC.value);

    const handleCheck = () => {
        dispatch(toggleCheckTC(!checkTC));
    };

    const handleContinue = () => {
        if (checkTC) {
            navigate("/assessment/Summary");
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        document.title = 'Prodivio Forge - Welcome';
    }, []);

    return (

        <div className="appContainer">
            <Header />
            <div className="screenContainer">
                <div className="titleContainer">
                    <h1>Welcome to Prodivio Forge</h1>
                </div>
                <div className="welcomeTextContainer">
                    <p><span className="highlight">Forge your path to innovation.</span> Prodivio Forge is your trusted partner in transforming your business ideas into realities. We offer a user-friendly platform with a strategic questionnaire meticulously crafted to assess your opportunities and spark innovation.</p>
                    <h3>What we offer:</h3>
                    <ul>
                    <li><span className="highlight">Assessment:</span> Answer thought-provoking questions to define and refine your ideas.</li>
                    <li><span className="highlight">Analysis:</span> Our advanced algorithms dissect your responses, offering comprehensive insights into your potential product or service.</li>
                    <li><span className="highlight">Recommendations:</span> Get tailored suggestions to optimize your project's success.</li>
                    </ul>
                </div>
                <div className="tcContainer">
                    <p>By using Prodivio Forge, you agree to our Terms & Conditions. Please note:</p>
                    <ul>
                        <li><span className="highlight">Prototype:</span> Prodivio Forge is currently a prototype and may contain bugs. While we strive to provide accurate results, we are not responsible for potential data loss.<span className="warning"> Warning: at this stage, we don't guarantee that Prodivio Forge will save the information you submit after the app is closed or refreshed.</span></li>
                        <li><span className="highlight">Your Responsibility:</span> You are solely responsible for the business outcomes of your projects and the use of the insights and recommendations provided by Prodivio Forge.</li>
                        <li><span className="highlight">Intellectual Property Protection:</span> Prodivio Forge is committed to protecting your IP. We do not claim ownership of your ideas or assessment data and take all reasonable measures to ensure their confidentiality. The assessment questionnaire, analysis models, and app are Prodivio Forge's intellectual property.</li>
                    </ul>
                    <div className="labelWithCheckbox">
                        
                    <label className="tcLAbel">
                        <Checkbox 
                            checked={checkTC}
                            onChange={handleCheck}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        I agree to the terms and conditions
                    </label>
                    </div>
                    <p className="tagLine">Ready to unlock the potential of your ideas?</p>
                </div>
                <div className="buttonContainer">
                <button
                disabled={!checkTC}
                className={`button ${checkTC ? 'activeButton' : 'disabledButton'}`}
                onClick={handleContinue}
                >
                    Continue
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}