import React, { useEffect, useState } from "react";
import { Checkbox, Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setQuestionValue, calculateLensMetrics } from "../features/questionnaire/questionnaireSlice";
import '../styles/AssessmentQuestion.css';
import {debounce } from 'lodash';

export default function AssessmentQuestion({question}) {
  const { id, question: questionText, labels, followUpQuestion } = question;
  
  const questionValue = useSelector((state) => state.questionnaire.questionValues[id] || null);

  const dispatch = useDispatch();

  const [sliderValue, setSliderValue] = useState(questionValue);


  const handleCheckboxChange = () => {
    const newValue = questionValue === -1 ? null : -1; // Toggle between null and -1
    dispatch(setQuestionValue({ questionId: Number(id), value: newValue }));
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleSliderChangeCommitted = (event, newValue) => {
    dispatch(setQuestionValue({ questionId: Number(id), value: newValue }));
  };
  
  useEffect(() => {
    dispatch(calculateLensMetrics());
  }, [questionValue]);


  return (
    <div key={questionValue} className="questionContainer">
        <p>{questionText}</p>
        <div className="answerContainer">
            <div className="sliderContainer">
                <p className="sliderLabel">{labels[1]}</p>
                <Slider
                  className={`sliderAnswer ${questionValue === null ? 'nullValue' : ''}`}
                  min={1}
                  max={10}
                  value={sliderValue}
                  onChange={handleSliderChange}
                  onChangeCommitted={handleSliderChangeCommitted}
                  valueLabelDisplay="auto"
                  disabled={questionValue === -1}
                  style={{
                    color: questionValue === null ? 'grey' : 'primary',
                  }}
                />
                <p className="sliderLabel">{labels[2]}</p>
             </div>

             <div className="checkboxContainer">
              <div className="checkboxWrapper">
                <Checkbox 
                  checked={questionValue === -1}
                  onChange={handleCheckboxChange}
                  className="checkboxAnswer"
                />
                <p className="checkboxLabel">{labels[0]}</p>
              </div>
            </div>
            
        </div>
        {questionValue !== null && questionValue !== -1 && followUpQuestion && followUpQuestion.length > 0 && (
                <div className="followUpContainer">
                    {followUpQuestion.map((followUp) => (
                        <AssessmentQuestion key={followUp.id} question={followUp} />
                    ))}
                </div>
          )}
    </div>
  )
}