import React, { useState, useEffect } from "react";
import { TextField, FormControl, RadioGroup, FormLabel, FormControlLabel, Radio, Button } from "@mui/material";
import '../styles/AssessmentSummary.css';
import { setOpportunityData, clearQuestionnaire, calculateLensMetrics } from "../features/questionnaire/questionnaireSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AssessmentSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opportunityName, setOpportunityName] = useState("");
  const [email, setEmail] = useState("");
  const [opportunityType, setOpportunityType] = useState("");
  const questionnaireValueData = useSelector(state => state.questionnaire.questionValues);

  const handleOpportunityNameChange = (event) => {
    setOpportunityName(event.target.value);
    dispatch(setOpportunityData({ opportunityName: event.target.value, email, opportunityType }));
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    dispatch(setOpportunityData({ opportunityName, email: event.target.value, opportunityType }));
  };

  const handleOpportunityTypeChange = (event) => {
    setOpportunityType(event.target.value);
    dispatch(setOpportunityData({ opportunityName, email, opportunityType: event.target.value }));
  };

  const handleClearData = () => {
    // Dispatch the action to clear the questionnaire state
    dispatch(clearQuestionnaire());
  }

  const handleStartAssessment = () => {
        navigate("/assessment/Market-&-Environment");
        window.scrollTo(0, 0);
}

  const initialData = useSelector(state => state.questionnaire.opportunityData);

  useEffect(() => {
    setOpportunityName(initialData.opportunityName);
    setEmail(initialData.email);
    setOpportunityType(initialData.opportunityType);
  }, [initialData]);

  useEffect(() => {
    dispatch(calculateLensMetrics());
  }, [questionnaireValueData]);

  return (
    <div className="summaryIntroduction">
      <p>
      Welcome to the Prodivio Forge Opportunity Assessment Center, which is tailored for individual opportunities. This assessment aims to provide insights into the current status of your idea and pinpoint essential innovation factors.
      </p>
      <p>
      The assessment is segmented into eight categories, each featuring a series of questions. Kindly respond to each question to the best of your ability. After completing the assessment, you'll gain access to your results and forthcoming recommendations for the next steps (Coming soon).
      </p>
      <h3>Opportunity Information</h3>
      <div className="summaryInputContainer">
        <FormControl className="formControl">

            <TextField
              className="opportunityInput"
              id="opportunityName"
              label="Opportunity Name"
              variant="outlined"
              value={opportunityName}
              onChange={handleOpportunityNameChange}
              margin="dense"
            />

          {/*<TextField
            className="opportunityInput"
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            margin="dense"
  />*/}

          <FormLabel component="legend" id="opportunityType">Type of Opportunity</FormLabel>
          <RadioGroup
            className="summaryInput"
            aria-labelledby="opportunity-type-radio-buttons-group"
            name="radioGroup"
            row
            value={opportunityType}
            onChange={handleOpportunityTypeChange}
            margin="normal"
          >
            <FormControlLabel value="software" control={<Radio />} label="Software" />
            <FormControlLabel value="hardware" control={<Radio />} label="Physical Product" />
            <FormControlLabel value="combination" control={<Radio />} label="Physical and Software Product" />
          </RadioGroup>
        </FormControl>

      <div className="navigationButtonContainer">
        <button className="fowardNavButton activeButton singleButton" onClick={handleStartAssessment} >Start Assessment</button>
      </div>

      </div>
      <hr className="divier" />
      <div className="clearDataContainer">
        <h3>Clear Data</h3>
        <p>If you wish to clear the questionnaire use this button. <span className="warning">Warning: this is an irreversible action</span></p>
        <div className="buttonContainer">
        <Button variant="outlined" color="error" onClick={handleClearData} className="clearButton">
          Clear Data
        </Button>
        </div>
      </div>
    </div>
  );
}